import '../css/ProductInfo.css'
import { useMediaQuery } from "react-responsive";
import Tags from './Tags'
import Sorting from './Sorting'
import ProductFilters from './ProductFilters'
import { useEffect, useState } from 'react';
import BreadCrumbs from './BreadCrumbs'

const ProductInfo = (props) =>{

    let [filters, setFilters] = useState(false)
    let [sorting, setSorting] = useState(false)
    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });

    return(
        <div className="product__info">
            <div className="product__info-content">
                <BreadCrumbs />
                <div className="info__title">
                    <div className="info__title-title" style={props.title == 'Все товары' ? {display:'none'}: {}}>
                        Категория:
                    </div>
                    <div className='info__title-title-name' style={props.title == 'Все товары' ? {marginLeft:0}: {}}>
                        {props.title}
                        <span style={
  
                            (!isMobile ? {fontSize:'14px', color:'#a2a2a2', marginLeft:'12px'} : {display:'none'})
                            }>
                            Найдено {props.lenght}
                        </span>
                    </div> 
                    <div style={isMobile ? {fontSize:'14px', color:'#a2a2a2'} : {display:'none'}}>
                        Найдено {props.lenght}
                    </div>
                </div>
                {/* <Tags /> */}
                {isMobile 
                ?
                <div className='dropdown__filters'>
                    <div className="filter__mobile" onClick={()=>(
                        setFilters(!filters)
                    )}>
                        <div className="filter__body-mb">
                            Фильтры
                        </div>
                    </div>
                    <div className="sorting__mobile" onClick={() =>{
                         setSorting(!sorting)
                    }}>
                        <div className="sorting__mobile__body">
                            Сортировать по:
                        </div>
                    </div>
                </div> 
                : <Sorting />}
                { isMobile ? <ProductFilters filters={filters}/> : ''}
                { isMobile ? <Sorting sorting={sorting} params={props.param} /> : ''}
            </div>
        </div>
    )
}

export default ProductInfo