import '../css/Register.css'
import '../css/Login.css'
import { UserRegister } from '../../api/Login'
import { useEffect, useState } from 'react'


const Register = (props) =>{
    let [registered, setRegistered] = useState(false)
    let [policy, setPolicy] = useState(true)

    useEffect(() => {
        if (registered == 'ok'){
            setTimeout(document.location.reload(), 2000)
        }
    }, [registered])

    return(
        <div className={props.register ? 'register__content' : 'register__content register-closed'} style={{border:'none'}}>
                <form action="register" className='login__form' style={{border:'none'}}
                onSubmit={(event) => {
                    event.preventDefault()
                    if (event.target.policy.checked){
                        UserRegister(setRegistered, event)
                    } else {
                        setPolicy(false)
                        setTimeout(() =>{
                            setPolicy(true)
                        }, 1000)
                    }
                    
                }}
                >
                    <div className="login__title">Регистрация</div>
                    {registered == false || (typeof registered != 'object'
                ?
                    <div style={{fontWeight:400, color:'green'}}>
                        Вы успешно зарегестрировались
                    </div>
                :
                    <div style={{fontWeight:400, color:'red'}}>
                       {console.log(registered)}
                    </div>
                )}
                    <div className="login__input"><p>
                        <label htmlFor="login">Email</label></p><input type="text" name='email' />
                        {registered == false 
                                ||
                                ((typeof registered == 'object' && registered.error?.email) 
                                &&
                                <div style={{fontWeight:400, color:'red', maxWidth:'180px'}}>
                                    {registered.error?.email}
                                </div>)}
                    </div>
                    <div className='register__blocks'>
                        <div className="register__block">
                            <div className="login__input">
                                <p><label htmlFor="password">Имя</label></p><input type="text" name='first_name' />
                            </div>
                            <div className="login__input">
                                <p><label htmlFor="password">Пароль</label></p><input type="text" name='password' />
                                {registered == false 
                                ||
                                ((typeof registered == 'object' && registered.error?.password1) 
                                &&
                                <div style={{fontWeight:400, color:'red',  maxWidth:'180px'}}>{registered.error?.password1}</div>)}
                            </div>
                        </div>
                        <div className="register__block">
                            <div className="login__input">
                                <p><label htmlFor="password">Фамилия</label></p><input type="text" name='last_name' />
                            </div>
                            <div className="login__input">
                                <p><label htmlFor="password">Подтвердите пароль</label></p><input type="text" name='password2' />
                                {registered == false 
                                ||
                                ((typeof registered == 'object' && registered.error?.password2) 
                                &&
                                <div style={{fontWeight:400, color:'red', maxWidth:'180px'}}>
                                    {registered.error?.password2}
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'20px'}}>
                        <div className="login__button">
                            <div className='policy' style={{marginBottom:'12px'}}>
                                <input type="checkbox" name="policy"/>
                                <label htmlFor=""><a href="/agreement" style={{color:"rgb(209,0,120)", fontWeight:400, fontSize:'14px'}}>
                                    <span style={policy ? {} : {color:'red'}}>Согласен с политикой конфиденциальности</span>
                                    </a>
                                </label>
                            </div>
                            <button type='submit'>Регистрация</button>
                        </div>
                    <div>
                        <a href="" style={{textDecoration:'none'}}>Забыли пароль?</a>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Register