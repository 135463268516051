import Header from '../components/js/Header'
import Container from '../components/js/Container'
import Footer from '../components/js/Footer'
import HeaderMobile from '../components/js/HeaderMobile'
import { useMediaQuery } from "react-responsive";
import '../components/css/ChooseShop.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { GetAvailableProducts } from '../api/GetProducts';
import { GetUser } from '../api/Login';
import PATH from '../api/URL';


const ChooseShop = () => {

    let [available, setAvailable] = useState([])
    let [user, setUser] = useState([])
    let [dropdown, setDropdown] = useState({})

    useEffect(() =>{
        GetAvailableProducts(setAvailable)
        GetUser(setUser)
    }, [])

    useEffect(() => {
        let dict = {}
        for (let key in available){
            dict[key] = true
        }
        setDropdown({...dict})
    }, [available])

    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });

    return(
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
                <div className="choose__shop">
                {(user == [] || user == '-')
                        ?
                        <div style={{color:"#d10078"}}>
                            Чтобы продолжить оформление заказа, необходимо войти
                        </div>
                        :
                        <div className="choose__shop-body">
                        <div className="choose-shop-title">Выберете магазин, в котором хотите забрать заказ</div>
                        {available.length == 0 
                        ?
                            <div>
                                    Магазины не найдены. Возможно у вас нет товаров в корзине
                            </div>
                        :
                        available.map((item, index) =>(
                            <div className="choose-shop__card">
                                <div className="choose-shop-card_body">
                                    <div className="shop__card-adress">{item[0]}</div>
                                    <div className="shop__card-quantity" onClick={() =>{
                                        setDropdown({...dropdown, [`${index}`]:true})
                                    }}
                                    >{item?.[1]?.['stock']} товаров</div>
                                    <div className="shop__card-price">{item?.[1]?.['total']} руб</div>
                                    <div className="shop__card-button">
                                        <a href={'order-create/' + item[0]}><button>Выбрать магазин</button></a>
                                    </div>
                                </div>
                                <div className="choose-shop-card__detail" style={dropdown[`${index}`] ? {display:'block'} : {display:'none'}}>
                                        <div className="card-detail__body">
                                            <div className="card-detail__cards">
                                                {item[1]['products'][0].map((item2) =>(
                                                    <div className="cart-detail__item">
                                                        <div className="cart-detail__item-body">
                                                            <div className="cart-detail__item-img">
                                                                <img src={PATH + '/' + item2.image} alt=""
                                                                    style={{width:'80px'}}
                                                                />
                                                            </div>
                                                            <div className="cart-detail__item-data">
                                                                {item2.available[0] == item2.available[1] 
                                                                ?
                                                                <div className="detail__item-title" style={{color:'green', fontSize:'14px'}}>
                                                                    В наличии: {item2.available[0]} из {item2.available[1]} товаров
                                                                </div>
                                                                :
                                                                <div className="detail__item-title" style={{color:'red', fontSize:'14px'}}>
                                                                    В наличии: {item2.available[0]} из {item2.available[1]} товаров
                                                                </div>
                                                                }
                                                                <div className="detail__item-title">{item2.name}</div>
                                                                <div className="detail__item-title">{item2.price} руб</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <center><div onClick={() =>{
                                                setDropdown({...dropdown, [`${index}`]:false})
                                            }} style={{cursor:'pointer'}}>Скрыть</div></center>
                                        </div>
                                    </div>
                            </div>                       
                        ))}
                    </div>
                    }
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default ChooseShop