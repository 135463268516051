import axios from "axios";
import PATH from "./URL";

async function GetPriceValues(func, slug) {
    const response = await axios.get(`${PATH}/api/v1/get_price_values/${slug}`)
    return func(response.data)
}

async function GetStrengthValues(func, slug) {
    const response = await axios.get(`${PATH}/api/v1/get_strength_values/${slug}`)
    return func(response.data)
}

async function GetCountries(func, category=null){
    const response = await axios.get(`${PATH}/api/v1/get_all_countries/${category}`)
    return func(response.data)
}

export {GetPriceValues, GetStrengthValues, GetCountries}