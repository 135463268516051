import { useState } from 'react'
import '../css/Login.css'
import Login from './Login'
import { useSelector, useDispatch } from 'react-redux'
import { change } from '../../store/login.slice.js'


const ModalLogin = () =>{

    let [login, setLogin] = useState(true)
    let [register, setRegister] = useState(false)
    let clickLogin = useSelector((state) => state.login.clickLogin)
    let clickCross = useSelector((state) => state.login.clickCross)
    let dispatch = useDispatch()


    return(
        <div className={(clickLogin == true && clickCross == false) ? 'login login-opened' : 'login'}>
            <div className="login__body">
                <div className="login__cross" onClick={() =>{
                    dispatch(change())
                    setLogin(true)
                    setRegister(false)
                }}>x</div>
                <Login login={login} setLogin={setLogin} register={register} setRegister={setRegister}/>
            </div>
        </div>
    )
}

export default ModalLogin