import { useState } from 'react';
import {prices} from '../../../api/get_categories'
import '../../css/ProductFilter.css'
import RangeSlider from 'react-range-slider-input';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetPriceValues } from '../../../api/FilterApi';


const FilterPrice = (props) => {
    let category = useParams()
    const [price, setPrice] = useState(null)

    useEffect(() =>{
        Object.values({...category}).length == 0 ? GetPriceValues(setPrice, null) : GetPriceValues(setPrice, category.category_slug)
    }, [])

    useEffect(() =>{
        props.setcurrentVal(price)
    }, [price])

    return (
        <div className="filter">
            {price == null ||
            <div className="filter__body">
                <div className="filter__title">Цена</div>
                <div className="filter__content">
                    <div className='filter__input' style={{display:'block'}}>
                        <RangeSlider 
                            defaultValue={[price[0], price[1]]}
                            min={price[0]}
                            max={price[1]}
                            step={1}
                            onInput={(value) =>(
                                props.setcurrentVal(value)
                            )}
                        />
                        <div style={{marginTop:'12px'}}>От: <span>{props.currentVal?.[0]}</span> руб до: <span>{props.currentVal?.[1]}</span> руб</div>
                    </div>
                </div>
            </div>
}
        </div>
    )
}

export default FilterPrice