import { createSlice } from '@reduxjs/toolkit'


const quantitySlice = createSlice({
    name: 'quantity',
    initialState: {
        quantity: 0,
    },
    reducers: {
      addQuantity: (state) => {
        state.quantity += 1
        console.log(state.quantity);
      },
      deleteQuantity: (state) => {
        state.quantity -= 1
        if (state.quantity < 0 ) state.quantity = 0
      },
      setQuantity: (state, action) => {
        state.quantity = action.payload
      },
      unsetQuantity: (state, action) => {
        state.quantity -= action.payload
        if (state.quantity < 0 ) state.quantity = 0
      },
    },
  })


export const { addQuantity, deleteQuantity, setQuantity, unsetQuantity } = quantitySlice.actions

export default quantitySlice.reducer