import logo from '../../images/logo.png'
import '../css/HeaderMobile.css'
import burger from '../../images/burger.png'
import { useEffect, useState } from 'react'
import { categories } from '../../api/get_categories'
import ModalLogin from './ModalLogin'
import { GetUser } from '../../api/Login'
import GetAllCategories from '../../api/GetCategories.js'
import { useDispatch } from 'react-redux'
import { change } from '../../store/login.slice.js'
import AgePopup from './AgePopup.jsx'


const HeaderMobile = () =>{
    let dispatch = useDispatch()
    let [subcat, setSubcat] = useState({})
    let [nav, setNav] = useState(false)
    let [user, setUser] = useState(false)
    let [categories, setCategories] = useState(() => {
        if (localStorage.getItem('categories')){
            return JSON.parse(localStorage.getItem('categories')).data
        } else{
            return []
        }
    })

    useEffect(() => {
        GetUser(setUser)
        Object.keys(categories).length == 0 && GetAllCategories(setCategories)
    }, [])
    
    useEffect(() => {
        let dct = {}
        for (let i = 0; i < Object.keys(categories).length; i++){
            dct[i] = false
        }
        setSubcat(dct)
    }, [categories])

    return (
        <div>
            <AgePopup />
            <ModalLogin/>
            <header className="header-mb">
                <div className="header-mb__body">
                    <div className="header-mb__logo">
                        <a href="/"><img src={logo} alt="" /></a>
                    </div>
                    <div className={nav ? "header-mb__nav" : "header-mb__nav closed"} style={{borderRadius:'none'}}>
                        <div className="header-mb-line">
                            <img src={burger} alt="" onClick={() =>{
                                setNav(false)
                            }}/>
                            <span>Меню</span>
                        </div>
                        <div className="nav__element" style={{color:'rgb(209,0,120)'}}>
                            {!user || user == '-' 
                            ?
                            <div style={{color:'rgb(209,0,120)'}} onClick={() =>{
                                dispatch(change())
                            }}>
                                Вход
                            </div>                   
                            :
                            <div>
                                <span style={{color:'rgb(93,20,76)'}}>С возвращением, </span> <span><a href="/user" style={{color:'rgb(209,0,120)', textDecoration:'none'}}>{user.first_name}</a></span>
                            </div>
                            }
                        </div>
                        <div className="nav__element" style={{color:'rgb(209,0,120)'}}>
                            <a href="/cart" style={{color:'rgb(209,0,120)', textDecoration:'none'}}>Корзина</a>
                        </div>

                        {categories.map((item, index) =>(
                            <div className="nav__element" key={index} style={{position:'relative', zIndex:30 + index}}>
                                <div className='nav__element-content'>
                                    <a href={`/categories/${item.slug}`}style={{color:'rgb(93,20,76)'}}>{item.name}</a>
                                    <div className={subcat[index] ? 'nav__arrow arrow-opened' : 'nav__arrow'} onClick={
                                        () => (
                                            setSubcat({...subcat, [index]:!subcat[index]})
                                        )
                                    }></div>
                                </div>
                                <div className={subcat[index] ? 'nav__dropdown' : 'nav__dropdown closed'} style={{position:'relative', zIndex: 20 + index}}>
                                    {categories[index]['subcategories'].length > 1 ? 
                                    (categories[index]['subcategories'].map(subcat => 
                                        subcat.name != item.name && <div><a href={`/categories/${subcat.slug}`}style={{color:'rgb(93,20,76)'}}>{subcat.name}</a></div>)) 
                                        : 
                                        <div>{item.name}</div>}
                                </div>
                            </div>                        
                        ))}
                        {(!user || user != '-') &&
                            <div className="nav__element" style={{color:'rgb(209,0,120)'}}>
                                <a href="/logout" style={{color:'rgb(209,0,120)', textDecoration:'none'}}>Выход</a>
                            </div>
                        }                   
                    </div>
                    <div className="header-mb__menu">
                        <div className="menu__body">
                            <div className="catalog-line">
                                <img src={burger} alt="" onClick={() =>{
                                setNav(!nav)
                            }}/>
                                <span>Меню</span>
                            </div>
                            <div className="list-categories">
                                <div className="list-categories__content">
                                {categories.slice(0, 5).map((item, key) => <div key={key}><a href={`/categories/${item.slug}`}>{item.name}</a></div>)}
                                </div>
                                <div className="show__subcategories">
                                    <div onClick={() =>{
                                setNav(!nav)
                            }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default HeaderMobile