import '../../css/ProductFilter.css'


const FilterColorBeer = (props) => {
    return (
        <div className="filter">
        <div className="filter__body">
            <div className="filter__title">Цвет Пива</div>
            <div className="filter__content">
                <div className='filter__input'>
                    <input type="checkbox" name="color" value='Темное'
                        onClick={props.getInputValue}/>
                    <label>Темное</label>
                </div>
                <div className='filter__input'>
                    <input type="checkbox" name="color" value='Светлое'
                        onClick={props.getInputValue}/>
                    <label>Светлое</label>
                </div>
            </div>
        </div>
    </div>
    )
}

export default FilterColorBeer