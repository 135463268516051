import '../../css/ProductFilter.css'


const FilterColorVine = (props) => {
    return (
        <div className="filter">
        <div className="filter__body">
            <div className="filter__title">Цвет Вина</div>
            <div className="filter__content">
                <div className='filter__input'>
                    <input type="checkbox" name="color" value='Белое'
                        onClick={props.getInputValue}/>
                    <label>Белое</label>
                </div>
                <div className='filter__input'>
                    <input type="checkbox" name="color" value='Красное'
                        onClick={props.getInputValue}/>
                    <label>Красное</label>
                </div>
                <div className='filter__input'>
                    <input type="checkbox" name="color" value='Розовое'
                        onClick={props.getInputValue}/>
                    <label>Розовое</label>
                </div>
            </div>
        </div>
    </div>
    )
}

export default FilterColorVine