import axios from "axios";
import PATH from "./URL";
import { categories } from "./get_categories";

const key = 'categories'

async function GetAllCategories(func){
    if (localStorage.getItem(key)){
        let data = JSON.parse(localStorage.getItem(key))
        return func(data.data)
    } else{
        const response = await axios.get(`${PATH}/api/v1/get_all_categories`)
        localStorage.setItem(key, JSON.stringify(response));
        return func(response.data)
    }
}

export default GetAllCategories
