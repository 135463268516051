import Header from "../components/js/Header"
import Container from "../components/js/Container"
import { useMediaQuery } from "react-responsive";
import Footer from "../components/js/Footer";
import '../components/css/Header.css'
import HeaderMobile from "../components/js/HeaderMobile";


const AboutUs = () =>{

    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });

    return (
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
                <div className="title">О WineStore</div>
                <div style={{
                    padding:'10px', borderRadius:'12px', borderRight:'1px solid lightgray',
                    borderBottom:'1px solid lightgray', background:'rgb(245, 245, 245)'
                }}>
                    <div style={
                        {color:'#464445', marginBottom:'20px', 
                        fontSize:'18px', fontWeight:500,
                        textAlign:'center'}}> 
                        Для Вас мы собрали отличный  ассортимент вина и крепких напитков: 
                        как демократичные позиции на каждый день, 
                        так и премиальный алкоголь в подарок или к особенной дате.  
                    </div>
                    <div style={
                        {color:'#464445', marginBottom:'20px', 
                        fontSize:'18px', fontWeight:500, textAlign:'center'}}>
                        Если вы ищете “то самое вино” или есть желание открыть что то новое, 
                        планируете семейный ужин или банкет – вы можете полностью на нас положиться. 
                    </div>
                    <div style={
                        {color:'#464445', fontSize:'18px', 
                        fontWeight:500, textAlign:'center'}}>
                        Покупая алкогольную продукцию в Winestore, вы можете быть уверены не только в нас, 
                        но и во всей цепочке компаний, изготовивших продукт. При первом же желании клиента мы готовы показать все 
                        необходимые сертификаты соответствия, 
                        на все 100% гарантируем абсолютную подлинность каждой купленной у нас бутылки.
                    </div>
                </div>
                <div className="title" style={{marginTop:'20px'}}>Адреса наших магазинов</div>
                <div>
                    <div className="map">
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Abb2b1f7daf477fe1ac6d23c8be1af8f9571c40971246587f06ddb5e92db6e380&amp;source=constructor" width="100%" height="500px" frameborder="0"></iframe>
                    </div>
                    <div className="photos"></div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default AboutUs