import AddCartButton from './AddCartButton';
import { RemToCart } from '../../api/AddToCart';
import PATH from '../../api/URL';


const CartItemMB = (props) => {
    return (
        <div className={props.item.quantity == 0 ? 'cart__card dnone' : 'cart__card'}>
        <div className="card__body">
            <div className="card__info">
                <img src={PATH + '/' + props.item.image} alt="" />
                <div className="card-text">
                    <div className="card-text-title">{props.item.product}</div>
                    <div className="card__price">
                        {props.for_basket 
                        ?
                        <div>
                            от <span style={{color:'gray', textDecoration:'line-through'}}>{props.item.old_price} руб</span> <span>{props.item.price} руб</span>
                        </div>
                        : 
                        <div>
                            {props.item.price} руб
                        </div>
                        }
                    </div>
                    <div className="add-to-cart-btn-mb">
                        {props.for_basket 
                        ?
                        <AddCartButton product_id={props.item.id} change={props.ChangeProduct} cart={props.cart}/>
                        :
                        <div style={{color:'#464445'}}>
                            {props.item.quantity} шт.
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CartItemMB