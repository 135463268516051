import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages/Index'
import ProductDetail from './pages/ProductDetail'
import Cart from './pages/Cart'
import ProductCategories from './pages/ProductCategories'
import ChooseShop from './pages/ChooseShop';
import OrderCreate from './pages/OrderCreate';
import User from './pages/User';
import Search from './pages/Search'
import Agreement from './pages/Agreement';
import AboutUs from './pages/AboutUs';
import Contacts from './pages/Contacts';


function App() {
  return (
    <div>
      <BrowserRouter>
       <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/categories/:category_slug' element={<ProductCategories />} />
          <Route path='/product/:product_slug' element={ <ProductDetail />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/choose-shop' element={<ChooseShop />} />
          <Route path='/order-create/:shop_name' element={<OrderCreate />} />
          <Route path='/user' element={<User />} />
          <Route path='/search' element={<Search />} />
          <Route path='/agreement' element={<Agreement />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/contacts' element={<Contacts />} />
       </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
