function truncNum(price='0.0'){
    let str_price = price
    let index_point = str_price.indexOf('.')
    switch (true) {
        case str_price[index_point + 1] && str_price[index_point + 2] == 0:
            return str_price.substring(0, index_point + 3)
        case str_price[index_point + 2] && str_price[index_point + 3] == 0:
            return str_price.substring(0, index_point + 3)
        case str_price[index_point + 3] && str_price[index_point + 4] == 0:
            return str_price.substring(0, index_point + 4)
        default:
            return str_price
    }
}

export {truncNum}