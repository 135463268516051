import Header from '../components/js/Header'
import Container from '../components/js/Container'
import SliderWS from '../components/js/Slider'
import ProductFilters from '../components/js/ProductFilters'
import ProductInfo from '../components/js/ProductInfo'
import ProductContent from '../components/js/ProductContent'
import Pagination from '../components/js/Pagination'
import Footer from '../components/js/Footer'
import { useMediaQuery } from "react-responsive";
import HeaderMobile from '../components/js/HeaderMobile'
import SearchMobile from '../components/js/SearchMobile'
import {GetProductsByCat, FilterProducts, SortProduct} from '../api/GetProducts'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams, useSearchParams } from "react-router-dom";
import GetFilters from '../api/Filters'


const ProductCategories = () =>{

    let category = useParams()
    let [title, setTitle] = useState('')
    let [searchParams, setSearchParams] = useSearchParams()
    let [product, setProducts] = useState(['Загрузка...', 0, 'загрузка...', ''])
    let [filters, setFilters] = useState(['Цена', 'Регион'])
    let [loading, setLoading] = useState(false)


    useEffect(() => {
        {searchParams.get('page') 
        ?  
        GetProductsByCat(setProducts, searchParams.get('page'), category.category_slug) 
        :
        GetProductsByCat(setProducts, 0, category.category_slug)
    }
        GetFilters(setFilters, category.category_slug)
    }, [])

    const handleChange = (value) => {
        setProducts(value)
      }

      useEffect(() =>{
        if(searchParams.size > 1){
            FilterProducts(setProducts, searchParams, category.category_slug)
        } else if (searchParams.get('sort')){
            FilterProducts(setProducts, searchParams, category.category_slug)
            // SortProduct(setProducts, searchParams.get('page') - 1, searchParams.get('sort'), category.category_slug)
        } else {
            GetProductsByCat(setProducts, searchParams.get('page'), category.category_slug, searchParams)
        }
    }, [searchParams])

    useEffect(() =>{
        title?.length == 0 && setTitle(product[3])
        loading && setLoading(false)
    }, [product])

    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });

    return(
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
            {isMobile ? <SearchMobile /> : ''}
                <div className="product__grid">
                    {isMobile ? '' :                         
                        <div className="product__grid_filters">
                            <ProductFilters change={handleChange} filters={filters} loading={setLoading} />
                        </div>
                    }
                    <div className="product__grid_products">
                        <div className="product__grid-content">
                            <ProductInfo params={searchParams} title={title} lenght={product[2]}/>
                            <ProductContent product={product[0]} loading={loading}/>
                            <Pagination param={searchParams} count={product[1]} len={product[2]} loading={setLoading} />
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default ProductCategories