import '../css/SearchMobile.css'
import { useEffect, useState } from 'react'

const SearchMobile = () =>{
    let [search, setSearch] = useState('')

    let platform = 'notapple'
    console.log(navigator.userAgent);
    if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')){
        platform = 'apple'
    }

    return(
        <div className="search__mobile">
            <form action="/search/" className='search__mobile__form' method='get'>
                <input type="text" placeholder="Поиск товара" name='search' onChange={(event)=>{
                            setSearch(event.target.value)
                        }} />
                <button style={platform == 'notapple' ? {height:'auto'} : {}} type='submit'>Поиск</button>
            </form>
        </div>
    )
}

export default SearchMobile