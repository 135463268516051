import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './login.slice';
import quantityReducer from './quantity.slice';


export const store = configureStore({
    reducer:{
      login:loginReducer,
      quantity:quantityReducer
    }
  })