import { useState } from 'react'
import '../css/AddCartButton.css'
import { AddToCart } from '../../api/AddToCart'
import { useEffect } from 'react'
import { CheckCart, DelToCart } from '../../api/AddToCart'
import {addQuantity, deleteQuantity} from '../../store/quantity.slice.js'
import { useSelector, useDispatch } from 'react-redux'


const AddCartButton = (props) => {

    let [count, setCount] = useState(0)
    let dispatch = useDispatch()

    const ChangeProduct = (value) => {
        props.change(value)
    }

    useEffect(() =>{
        setCount(Number(props.cart[props.product_id]?.quantity) || 0)
    }, [])

    useEffect(() =>{
        setCount(Number(props.cart[props.product_id]?.quantity) || 0)
    }, [props.cart, props.product_id])

    return (
       <div>
         {count != 0
            ?
            <div>
                <div className="add-to-cart">
                    <div className='remove' onClick={() =>{
                        setCount(count -= 1)
                        DelToCart(props.product_id)
                        dispatch(deleteQuantity())
                        ChangeProduct({id:props.product_id, quantity: count})
                    }}>-</div>
                    <div className='quantity'>{count}</div>
                    <div className='add' onClick={() =>{
                        setCount(count += 1)
                        AddToCart(props.product_id)
                        dispatch(addQuantity())
                        ChangeProduct({id:props.product_id, quantity: count})
                    }}>+</div>
                </div>
            </div>
            :
            <div className="add-to-cart-button">
                <button onClick={() =>{
                    AddToCart(props.product_id)
                    setCount(count += 1)
                    dispatch(addQuantity())
                }}>Добавить в корзину</button>
            </div>
        }
       </div>
    )
}


export default AddCartButton