import { useState } from 'react'
import '../css/AgePopup.css'
import { useEffect } from 'react'
import { CheckPopup, ClickPopup } from '../../api/Popup'


const AgePopup = () => {
    let [click, setClick] = useState(false)

    useEffect(() =>{
        CheckPopup(setClick)
    }, [])

    return (
        <div className="age__popup" style={!click ? {display:'none'} : {}}>
            <div className="age__popup-content">
                <div className="age__popup-body">
                    <div className="age__popup-title">
                        Подтверждение возраста (18+)
                    </div>
                    <div className="age__popup-text">
                        <div style={{marginBottom:'12px'}}>                
                            На сайте содержится информация, не рекомендованная для лиц, не достигших совершеннолетнего возраста.
                        </div>
                        <div style={{marginBottom:'12px'}}>
                            Для доступа на сайт вы должны подтвердить свое совершеннолетие.
                        </div>
                        <div style={{marginBottom:'12px'}}>
                            Сведения, предоставленные на сайте, носят исключительно информационный характер и предназначены только для личного использования.
                        </div>
                        <button className="age__popup-button" onClick={
                            () =>{
                                ClickPopup()
                                setClick(false)
                            }
                        }>Мне есть 18 лет</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgePopup