import axios from 'axios';
import PATH from './URL'


function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) == (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

async function UserLogin(func, event){
    const csrftoken = getCookie('csrftoken');
    let options = {
        method:'POST',
        headers:{ 'X-CSRFToken': csrftoken, 'Content-Type' : 'application/x-www-form-urlencoded'},
        data:{'username':event.target.login.value, 'password':event.target.password.value},
        url: `${PATH}/login`
    }
    const response = await axios(options)
    console.log(response.data);
    return func(response.data)
}

async function UserRegister(func, event){
    const csrftoken = getCookie('csrftoken');
    let options = {
        method:'POST',
        headers:{ 'X-CSRFToken': csrftoken, 'Content-Type' : 'application/x-www-form-urlencoded'},
        data:{
            'email':event.target.email.value,
            'password1':event.target.password.value,
            'password2':event.target.password2.value,
            'first_name':event.target.first_name.value,
            'last_name':event.target.last_name.value
            },
        url: `${PATH}/register`
    }
    const response = await axios(options)
    return func(response.data)
}


async function GetUser(func){
    const response = await axios.get(`${PATH}/api/v1/get-user`)
    return func(response.data)
}


export {UserLogin, UserRegister, GetUser}