import '../css/Footer.css'
import { useSelector, useDispatch } from 'react-redux'
import { change } from '../../store/login.slice.js'
import GetAllCategories from '../../api/GetCategories.js'
import { useEffect, useState } from 'react'
import { GetUser } from '../../api/Login.js'


const Footer = () =>{
    let [user, setUser] = useState({})
    let dispatch = useDispatch()
    let [categories, setCategories] = useState(() => {
        if (localStorage.getItem('categories')){
            return JSON.parse(localStorage.getItem('categories')).data
        } else{
            return []
        }
    })

    useEffect(() => {
        GetUser(setUser)
        Object.keys(categories).length == 0 && GetAllCategories(setCategories)
    }, [])

    return (
        <div className="footer">
            <div className="footer__body">
                <div className="footer__block">
                    {categories.map((item, key) => 
                        <div className="block__subtitle" key={key}
                        style={key < 6 & key > 0 ? {} : {display:'none'}}>
                            <a href={`/categories/${item.slug}`}>{item.name}</a>
                        </div>
                    )}
                </div>
                <div className="footer__block">
                    <div className="block__title">Winestore</div>
                    <div className="block__subtitle">
                        <a href="/about-us">О магазине</a>
                    </div>
                    <div className="block__subtitle">
                        <a href="/contacts">Контактные данные</a>
                    </div>
                    <div className="block__subtitle">
                        <a href="/agreement">Персональные данные</a>
                    </div>
                    <div className="block__subtitle">
                        <a href="https://yandex.ru/maps/44/izhevsk/search/Winestore/?ll=53.256610%2C56.853847&sctx=ZAAAAAgBEAAaKAoSCQ68Wu7MoEpAEU890uC2bExAEhIJ3lUPmIdMyz8RcZAQ5QtasD8iBgABAgMEBSgKOABAwZ8NSAFqAnJ1nQHNzEw9oAEAqAEAvQGFGLnnwgEXzpiyp2TMj%2BOU%2FAOF6e6IqAOv4%2B3%2B2ALqAQDyAQD4AQCCAglXaW5lc3RvcmWKAgCSAgCaAgxkZXNrdG9wLW1hcHM%3D&sll=53.256610%2C56.853847&sspn=0.134976%2C0.040420&z=13.42">Адреса магазинов</a>
                    </div>
                </div>
                <div className="footer__block">
                    <div className="block__title">Навигация</div>
                    <div className="block__subtitle">
                        <a href="/">Домашняя страница</a>
                    </div>
                    <div className="block__subtitle">
                        <a href="/cart">Корзина</a>
                    </div>
                    {(user == {} || user == '-') ?
                    <div>
                        <div className="block__subtitle">
                            <a href="" onClick={(event) =>{
                                event.preventDefault()
                                dispatch(change())
                            }}>Вход</a>
                        </div>
                        <div className="block__subtitle">
                            <a href="" onClick={(event) =>{
                                event.preventDefault()
                                dispatch(change())
                            }}>Регистрация</a>
                        </div>
                    </div>
                    :
                    <div>
                    <div className="block__subtitle">
                        <a href="logout" >Выход</a>
                    </div>
                    <div className="block__subtitle">
                        <a href="/user" >Аккаунт</a>
                    </div>
                </div>
                    }
                </div>
                {/* <div className="footer__block" style={{fontSize:'14px', width:'70%'}}>
                    <div className="block__title">Предупреждение</div>
                    <div className="block__subtitle" style={{fontSize:'12px'}}>
                    ЦЕНЫ, ХАРАКТЕРИСТИКИ И ВНЕШНИЙ ВИД ТОВАРА В МАГАЗИНАХ МОГУТ ОТЛИЧАТЬСЯ ОТ УКАЗАННЫХ НА САЙТЕ. МАГАЗИНЫ «winestore» НЕ ОСУЩЕСТВЛЯЮТ ДИСТАНЦИОННУЮ ТОРГОВЛЮ, ДОСТАВКА ТОВАРА НЕ ПРОИЗВОДИТСЯ, ОПЛАТА ТОВАРА ПРОИСХОДИТ НЕПОСРЕДСТВЕННО В МАГАЗИНАХ «winestore» В СООТВЕТСТВИИ С ДЕЙСТВУЮЩИМ ЗАКОНОДАТЕЛЬСТВОМ РФ И РЕЖИМОМ РАБОТЫ МАГАЗИНОВ, КРУГЛОСУТОЧНАЯ И ДИСТАНЦИОННАЯ ПРОДАЖА АЛКОГОЛЬНОЙ ПРОДУКЦИИ НЕ ОСУЩЕСТВЛЯЕТСЯ. ИНФОРМАЦИЯ О ТОВАРАХ, РАЗМЕЩЕННАЯ НА САЙТЕ НОСИТ ОЗНАКОМИТЕЛЬНЫЙ ХАРАКТЕР, ПОДРОБНОСТИ О ПРИОБРЕТЕНИИ ТОВАРОВ УТОЧНЯЙТЕ В МАГАЗИНАХ «winestore»
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Footer