import '../css/Header.css'
import logo from '../../images/logo.png'
import cart from '../../images/cart.svg'
import login from '../../images/login.svg'
import account from '../../images/account.svg'
import logout from '../../images/logout.svg'
import location from '../../images/location.svg'
import burger from '../../images/burger.png'
import { useEffect, useState } from 'react'
import ModalLogin from './ModalLogin.jsx'
import { GetUser } from '../../api/Login'
import GetAllCategories from '../../api/GetCategories.js'
import AgePopup from './AgePopup.jsx'
import { useSearchParams } from "react-router-dom";
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { change } from '../../store/login.slice.js'
import {getLenCart} from '../../api/AddToCart.js'
import {setQuantity} from '../../store/quantity.slice.js'


const Header = () =>{

    let [menu, setMenu] = useState(false)
    let [user, setUser] = useState({})
    let [categories, setCategories] = useState(() => {
        if (localStorage.getItem('categories')){
            return JSON.parse(localStorage.getItem('categories')).data
        } else{
            return []
        }
    })
    let [search, setSearch] = useState('')
    let [popup, setPopup] = useState(false)
    let [cartQuantity, setCart] = useState(0)
    let [searchParams, setSearchParams] = useSearchParams()
    let dispatch = useDispatch()
    let dispatchQuantity = useDispatch()
    let quantity = useSelector((state) => state.quantity.quantity)
    useEffect(() => {
        GetUser(setUser)
        Object.keys(categories).length == 0 && GetAllCategories(setCategories)
        getLenCart(setCart)
    }, [])

    useEffect(() =>{
        console.log(cartQuantity);
        dispatchQuantity(setQuantity(cartQuantity))
    }, [cartQuantity])


    return (
        <div>
            <AgePopup />
            <ModalLogin />
            <header className="header">
        <div className="header__body">
            <div className="header__content">
                <div className="header__logo"><a href="/"><img src={logo} alt="" /></a></div>
                <div className="search">
                    <form action="/search/" method='get' className="search__form">
                        <input type="text" placeholder="Поиск товара" name='search' onChange={(event)=>{
                            setSearch(event.target.value)
                        }} />
                        <button type='submit'>Поиск</button>
                    </form>
                </div>
                <div className="header__text">
                    <div>
                        <a href="https://yandex.ru/maps/44/izhevsk/search/Winestore/?ll=53.256610%2C56.853847&sctx=ZAAAAAgBEAAaKAoSCQ68Wu7MoEpAEU890uC2bExAEhIJ3lUPmIdMyz8RcZAQ5QtasD8iBgABAgMEBSgKOABAwZ8NSAFqAnJ1nQHNzEw9oAEAqAEAvQGFGLnnwgEXzpiyp2TMj%2BOU%2FAOF6e6IqAOv4%2B3%2B2ALqAQDyAQD4AQCCAglXaW5lc3RvcmWKAgCSAgCaAgxkZXNrdG9wLW1hcHM%3D&sll=53.256610%2C56.853847&sspn=0.134976%2C0.040420&z=13.42">
                            <img src={location} alt="" style={{width:'40px', marginLeft:'10px'}}/>
                            <div style={{fontSize:'12px', marginLeft:0}}>Магазины</div>
                        </a>
                    </div>
                    {/* <div style={{display:'flex', alignItems:'center', fontWeight:'400'}}>+7 123 456 88 99</div> */}
                    {user == {} || user == '-' 
                    ?
                    <div style={{cursor:'pointer'}} onClick={() =>{
                        dispatch(change())
                    }}>
                        <img style={{width:'40px'}} src={login} alt="" />
                        <div style={{fontSize:'12px', marginLeft:'12px'}}>Вход</div>
                    </div>
                    :
                    <div>
                        <a href="/user" style={{textDecoration:'none'}}><img src={account} style={{width:'40px'}} alt="" /></a>
                        <div style={{color:'#d10078', fontSize:'12px', marginLeft:'4px'}}>                        
                            {user.first_name}
                        </div>
                    </div>
                    }
                    {(user == {} || user == '-') || 
                    <div>
                        <a href="logout" style={{textDecoration:'none'}}> <img src={logout} style={{width:'40px'}} alt="" /></a>
                        <div style={{fontSize:'12px', marginLeft:'0px'}}>Выход</div>
                    </div> }
                    <div className="cart">
                        <a href="/cart"><img src={cart} style={{width:'40px'}} alt="" /></a>
                        <div className='cart__quantity'><span>{quantity}</span></div>
                        <div style={{fontSize:'12px', marginLeft:0}}>Корзина</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="header__catalog">
            <div className="header__catalog-content">
                <div className={menu == true ? 'header__catalog__button catalog_btn-opened' : 'header__catalog__button'} onClick={() =>{
                    setMenu(!menu)
                }} style={{cursor:'pointer'}}>
                    <img src={burger} alt="" style={{width: '30px', zIndex: 3}} />
                    <div style={{marginLeft:'4px', zIndex:3}}>Каталог</div>
                    <div className={menu == true ? 'rectangle rectangle-opened' : 'rectangle'} id="rectangle"></div>

                </div>
                <div className={menu == true ? 'header__catalog_categories tape_btn-opened' : 'header__catalog_categories'} id="tape_btn">
                    {categories.map((item, key) => <div key={key}><a href={`/categories/${item.slug}`}>{item.name}</a></div>)}
                </div>
                <div className={menu == true ? 'header__catalog-submenu dropdown-opened' : 'header__catalog-submenu'} id="menu">
                    <div className="submenu__body">
                        <div className="submenu__content">
                            <div className="content__block-left">
                                {categories.map((item, key) => 
                                <div key={key}>
                                    {key < 4 
                                    &&
                                    <div className="submenu__content-title">
                                        {item.name}
                                        <div className='submenu__content__block'>
                                            {categories[[key]]['subcategories'].map((subtitle, key) =>(
                                                <span className="submenu__content-subtitle" key={key}>
                                                    <a href={`/categories/${subtitle.slug}`} style={{color: 'rgb(209,0,120)'}}>{subtitle.name}</a>
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    }
                                </div>
                                )}
                            </div>
                            <div className="content__block-right">
                                {categories.map((item, key) => 
                                <div key={key}>
                                    {key > 3 
                                    &&
                                    <div className="submenu__content-title">
                                        {item.name}
                                        <div className='submenu__content__block'>
                                            {categories[[key]]['subcategories'].map((subtitle, key) =>(
                                                <span className="submenu__content-subtitle" key={key}>
                                                    <a href={`/categories/${subtitle.slug}`} style={{color: 'rgb(209,0,120)'}}>{subtitle.name}</a>
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                    }
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
        </div>
    )
}

export default Header