import Header from '../components/js/Header'
import Container from '../components/js/Container'
import SliderWS from '../components/js/Slider'
import ProductFilters from '../components/js/ProductFilters'
import ProductInfo from '../components/js/ProductInfo'
import ProductContent from '../components/js/ProductContent'
import Pagination from '../components/js/Pagination'
import Footer from '../components/js/Footer'
import { useMediaQuery } from "react-responsive";
import HeaderMobile from '../components/js/HeaderMobile'
import SearchMobile from '../components/js/SearchMobile'
import {GetAllProducts, FilterProducts, SortProduct} from '../api/GetProducts'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSearchParams } from "react-router-dom";
import GetFilters from '../api/Filters'
import AgePopup from '../components/js/AgePopup'

const Index = () =>{
    
    let [searchParams, setSearchParams] = useSearchParams()
    let [loading, setLoading] = useState(false)
    let [product, setProducts] = useState(['Загрузка...'])
    let [filters, setFilters] = useState(['Цена', 'Регион'])

    useEffect(() => {
        {searchParams.get('page') 
        ?  
        GetAllProducts(setProducts, searchParams.get('page')) 
        :
        GetAllProducts(setProducts)
    }
    
    }, [])

    const handleChange = (value) => {
        setProducts(value)
      }

    useEffect(() =>{
        if(searchParams.size > 1){
            FilterProducts(setProducts, searchParams)
        } else if (searchParams.get('sort')){
            FilterProducts(setProducts, searchParams)
        } else {
            GetAllProducts(setProducts, searchParams.get('page'), searchParams)
        }
    }, [searchParams])

    useEffect(() =>{
        loading && setLoading(false)
    }, [product])

    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });

    return(
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
            {isMobile ? <SearchMobile /> : ''}
                <SliderWS for_main_page={true} />
                <div className="product__grid">
                    {isMobile ? '' :                         
                        <div className="product__grid_filters">
                            <ProductFilters change={handleChange}  filters={filters} loading={setLoading}/>
                        </div>
                    }
                    <div className="product__grid_products">
                        <div className="product__grid-content">
                            <ProductInfo params={searchParams} title='Вино' lenght={product[2]}/>
                            <ProductContent product={product[0]} loading={loading}/>
                            <Pagination param={searchParams} count={product[1]} len={product[2]} loading={setLoading}/>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default Index