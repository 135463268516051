import { useEffect, useState } from 'react'
import '../css/Login.css'
import Register from './Register'
import { UserLogin } from '../../api/Login'

const Login = (props) =>{
    let [logined, setLogined] = useState(false)
    useEffect(() => {
        if (logined == 'ok'){
            setTimeout(document.location.reload(), 2000)
        }
    }, [logined])
    
    return(
        <div>
            <div className={props.login ? 'login__content' : 'login__content login-closed'}>
                {logined == false || (typeof logined != 'object'
                ?
                    <div style={{fontWeight:400, color:'green'}}>
                        Вы успешно вошли
                    </div>
                :
                    <div style={{fontWeight:400, color:'red'}}>
                       {logined['error']['__all__'][0]}
                    </div>
                )}
                <form action="login" method='post' className='login__form' onSubmit={
                    (event) => {
                        event.preventDefault()
                        UserLogin(setLogined, event)
                    }
                }>
                    <div className="login__title">Войти</div>
                    <div className="login__input"><p><label htmlFor="login">Email</label></p><input type="text" name='login'/></div>
                    <div className="login__input"><p><label htmlFor="password">Пароль</label></p><input type="password" name='password' /></div>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'20px'}}>
                        <div className="login__button">
                            <button type='submit'>Войти</button>
                        </div>
                        <div>
                            <a href="" style={{textDecoration:'none'}}>Забыли пароль?</a>
                        </div>
                    </div>
                </form>
                <div className="register__buton">
                    <button onClick={() =>{
                        props.setLogin(!props.login)
                        props.setRegister(!props.register)
                    }}>Зарегестрироваться</button>
                </div>
            </div>
            <Register register={props.register}/>
        </div>
    )
}


export default Login