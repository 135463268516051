import axios from "axios";
import PATH from "./URL";

async function CheckPopup(func){
    const response =  await axios.get(`${PATH}/api/v1/check_popup`)
    return func(response.data)
}

async function ClickPopup(){
    await axios.get(`${PATH}/api/v1/click_popup`)
    return undefined
}

export {CheckPopup, ClickPopup}