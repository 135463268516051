import { useState } from 'react'
import {regions} from '../../../api/get_categories'
import '../../css/ProductFilter.css'
import { useEffect } from 'react'
import { GetCountries } from '../../../api/FilterApi'
import { useParams } from 'react-router-dom'


const FilterRegion = (props) => {
    let [regions, setRegions] = useState([])
    let param = useParams()

    useEffect(() =>{
        GetCountries(setRegions, param.category_slug)
    }, [])
    return (
        <div className="filter">
            <div className="filter__body">
                <div className="filter__title">Регион</div>
                    <div className="filter__content">
                        {regions.map((el, index) =>(
                            <div className='filter__input' key={index}>
                                <input type="checkbox" name="region" value={el.country__name} id={'reg'+ index}
                                onClick={props.getInputValue} />
                                <label htmlFor={'reg'+ index}>{el.country__name}</label>
                            </div>                              
                        ))}
                    </div>
                </div>
        </div>
    )
}

export default FilterRegion