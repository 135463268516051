import { useEffect, useState } from 'react'
import '../css/ProductInfo.css'
import { useParams, useSearchParams } from "react-router-dom";


const Sorting = (props) =>{

    let category = useParams()
    let [sort, setSort] = useState('default')
    let [searchParam, setSearchParam] = useSearchParams()

    const handleUrl = () =>{
        let param = new URLSearchParams(document.location.search)
        let filter_param = {
            region:param.get('region'),
            price:param.get('price'),
            strength:param.get('strength'),
            volume:param.get('volume'),
            sugar:param.get('sugar'),
            color:param.get('color'),
            sort:sort,
            page:param.get('page'),

        }
        for (let value of Object.keys(filter_param)){
            if (filter_param[value] == null){
                delete filter_param[value]
            }
        }
        
        setSearchParam({...filter_param})
    }

    useEffect(() =>{
        {sort == 'default' || handleUrl()}
    }, [sort])


    return(
        <div className={props.sorting ? 'sorting sorting-opened' : 'sorting'}>
            <div className="sorting__title">Сортировать по:</div>
            <div className="sorting__tag" onClick={() =>{
                setSort('price-down')
            }}>
                <div className="sorting__tag__body">
                    Цене ↓
                </div>
            </div>
            <div className="sorting__tag" onClick={() =>{
                setSort('price-up')
            }}>
                <div className="sorting__tag__body">
                    Цене ↑
                </div>
            </div>
            <div className="sorting__tag" onClick={() =>{
                setSort('name')
            }}>
                <div className="sorting__tag__body">
                    Названию
                </div>
            </div>
        </div>
    )
}

export default Sorting