import Container from "../components/js/Container"
import Header from "../components/js/Header"
import Footer from "../components/js/Footer"
import { useMediaQuery } from "react-responsive";
import HeaderMobile from '../components/js/HeaderMobile'
import { useState } from "react";
import { GetUserOrders, UpdateUserData } from "../api/UserData";
import { GetUser } from "../api/Login";
import { useEffect } from "react";
import '../components/css/User.css'
import CartItemMB from "../components/js/CartItemMB";
import CartItemPC from "../components/js/CartItemPC";


const checkUpdate = (update) =>{
    switch (typeof update) {
        case 'string':
            return (
                <div style={{color:'gray', marginTop:'10px'}}>
                    Обновление данных...
                </div>
            )
        case 'object':
            return (
                <div style={{color:'red', marginTop:'10px'}}>
                    Ошибка
                </div>
            )
        case 'number':
            return (<div style={{color:'green', marginTop:'10px'}}>
                Данные успешно обновлены
            </div>
            )
        default:
            break;
    }
}

const User = () =>{

    let [update, setUpdate] = useState(undefined)
    let [user, setUser] = useState({})
    let [orders, setOrders] = useState([])

    useEffect(() =>{
        GetUser(setUser)
        GetUserOrders(setOrders)
    }, [])

    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });

    return (
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
                {user == '-' 
                ?
                <div style={{color:'#464445'}}>
                    Вы не вошли в аккаунт.
                </div>
                 :
                 <div>
                <div className="user">
                    <div className="title">Аккаунт</div>
                        <form action="" method="post" onSubmit={(event) => {
                            event.preventDefault()
                            setUpdate('process')
                            UpdateUserData(setUpdate, user)
                            GetUser(setUser)
                        }}>
                            <div className="user__input">
                               <div> <label htmlFor="email">Email</label></div>
                                <input type="text" name="email" value={user.email} onChange={(event)=>{
                                    setUser({...user, email:event.target.value})
                                }}/>
                                {typeof update == 'object' &&
                                <div style={{color:'red'}}>
                                    {update.error.email}
                                </div>
                                }
                            </div>
                            <div className="user__input">
                                <div><label htmlFor="last_name">Фамилия</label></div>
                                <input type="text" name="last_name" value={user.last_name} onChange={(event)=>{
                                    setUser({...user, last_name:event.target.value})
                                }} />
                                {typeof update == 'object' &&
                                <div style={{color:'red'}}>
                                    {update.error.last_name}
                                </div>
                                }
                            </div>
                            <div className="user__input" style={{marginBottom:0}}>
                                <div><label htmlFor="first__name">Имя</label></div>
                                <input type="text" name="first_name" value={user.first_name} onChange={(event)=>{
                                    setUser({...user, first_name:event.target.value})
                                }}/>
                                {typeof update == 'object' &&
                                <div style={{color:'red'}}>
                                    {update.error.first_name}
                                </div>
                                }
                            </div>
                            <div className="user__button">
                                {checkUpdate(update)}
                                <button type="submit" style={{marginTop:'10px'}}>Обновить Данные</button>
                            </div>
                        </form>
                </div>
                <div className="title" style={{marginTop:'12px'}}>Заказы</div>
                    {orders.map((item) =>(
                        <div className="order">
                            <div className="order__body">
                                <div className="title" style={{fontSize:'20px'}}>
                                    Заказ #{item.order_id} от {item.created.split(' ')[0]}
                                </div>
                                {item.orders.map((item2, index) => (
                                    <div>{isMobile 
                                        ?
                                        <CartItemMB
                                        key={index}
                                        item={item2}
                                        for_basket={false} 
                                        />
                                        :
                                        <CartItemPC
                                        key={index}
                                        item={item2}
                                        for_basket={false} 
                                    />
                                    }</div>
                                ))}
                            </div>
                        </div>
                ))}
                </div>
            }       
            </Container>
            <Footer />
        </div>
    )
}

export default User