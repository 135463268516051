import Slider from "react-slick";
import image from '../../images/1.jpg'
import '../css/Slider.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import GetSlides from "../../api/GetSlides";
import PATH from "../../api/URL";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", right:0, zIndex:20 }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", left:0, zIndex:20 }}
        onClick={onClick}
      />
    );
  }

  const SliderWS = (props) =>{

    const [images, setImages] = useState([]);

    useEffect(() => {
      GetSlides(setImages)
    }, [])
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };

      return(
        <Slider {...settings}>
          {images.map((item) =>
            item.for_main_page == props.for_main_page &&
              <div>
              <a href={item.url}><img src={PATH + '/' + item.image} alt="" /></a>
            </div>
            
          )}
  </Slider>
      )
  }

  export default SliderWS