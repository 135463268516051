import '../css/ProductInfo.css'


const BreadCrumbs = ({category='Главная', href='/'}) =>{
    return(
        <div className="breadcrumbs">
            <a href={href} className='breadcrumbs__link'>Главная</a> {category != 'Главная' && <span> - <a href="#" className='breadcrumbs__link'>{category}</a></span>}
        </div>
    )
}

export default BreadCrumbs