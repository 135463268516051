import '../../css/ProductFilter.css'
import RangeSlider from 'react-range-slider-input';
import { GetStrengthValues } from '../../../api/FilterApi';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';



const FilterStrenght = (props) => {
    let category = useParams()
    let [strength, setStrength] = useState([0, 100])
    
    useEffect(() =>{
        Object.values({...category}).length == 0 ? GetStrengthValues(setStrength, null) : GetStrengthValues(setStrength, category.category_slug)
    }, [])

    useEffect(() =>{
        console.log(strength);
        props.setcurrentVal(strength)
    }, [strength])
    return (
        <div className="filter">
            {strength == null ||
            <div className="filter__body">
                <div className="filter__title">Крепость</div>
                <div className="filter__content">
                    <div className='filter__input' style={{display:'block'}}>
                        <RangeSlider 
                            defaultValue={[strength[0], strength[1]]}
                            min={strength[0]}
                            max={strength[1]}
                            step={0.2}                            
                            onInput={(value) =>(
                                props.setcurrentVal(value)
                            )}
                        />
                        <div style={{marginTop:'12px'}}>От: <span>{props.currentVal?.[0]}</span>% до: <span>{props.currentVal?.[1]}</span>%</div>
                    </div>
                </div>
            </div>
}
        </div>
    )
}

export default FilterStrenght