import Header from '../components/js/Header'
import Container from '../components/js/Container'
import SliderWS from '../components/js/Slider'
import ProductFilters from '../components/js/ProductFilters'
import ProductInfo from '../components/js/ProductInfo'
import ProductContent from '../components/js/ProductContent'
import Pagination from '../components/js/Pagination'
import Footer from '../components/js/Footer'
import { useMediaQuery } from "react-responsive";
import HeaderMobile from '../components/js/HeaderMobile'
import SearchMobile from '../components/js/SearchMobile'
import {GetAllProducts, FilterProducts, SortProduct} from '../api/GetProducts'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams, useSearchParams } from "react-router-dom";
import { SearchProducts } from '../api/GetProducts'


const Search = () =>{
    let [loading, setLoading] = useState(false)
    let search = new URLSearchParams(document.location.search)
    let [products, setProducts] = useState(['loading'])
    let [len, setLen] = useState('...')
    let [searchParams, setSearchParams] = useSearchParams()


    useEffect(() => {
        SearchProducts(setProducts, search.get('search'), 1)
    }, [])

    useEffect(() => {
        SearchProducts(setProducts, search.get('search'), search.get('page'))
        console.log(products);
    }, [searchParams])

    useEffect(() => {
        typeof products[0] != 'string' && setLen(products[2])
        loading && setLoading(false)
    }, [products])
    
    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });

    return(
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
            {isMobile ? <SearchMobile /> : ''}
                <div className="product__grid" style={{display:'block'}}>
                    <div style={{fontSize:'26px', color:'rgb(70, 68, 69)'}}>
                        Результаты поиска: {search.get('search')} <span style={
                            {fontSize:'14px', color: 'rgb(162, 162, 162)'}
                        }>найдено {len}</span>
                    </div>
                    <div className="product__grid_products">
                        <div className="product__grid-content">
                            <ProductContent product={products[0]} loading={loading} />
                            <Pagination param={search} count={products[1]} len={products[2]} loading={setLoading}/>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default Search