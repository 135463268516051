import Header from '../components/js/Header'
import Container from '../components/js/Container'
import Footer from '../components/js/Footer'
import HeaderMobile from '../components/js/HeaderMobile'
import { useMediaQuery } from "react-responsive";
import '../components/css/OrderCreate.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import axios from 'axios';
import PATH from '../api/URL';
import SaveOrder from '../api/OrderCreate';
import { GetUser } from "../api/Login";


const OrderCreate = () => {

    let param = useParams()
    let [total, setTotal] = useState({products:[]})
    let [order, setOrder] = useState({})
    let [user, setUser] = useState({})

    useEffect(() =>{
        GetUser(setUser)
    }, [])

    async function getTotal(func){
        const response = await axios.get(`${PATH}/api/v1/order-create/${param.shop_name}`)
        return func(response.data)
    }
    useEffect(() =>{
        getTotal(setTotal)
    }, [])
    
    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });

    return(
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
                {Object.values(order).length == 0 
                ?
                <div className="order-create">
                    <div className="title">
                        Контактные данные
                    </div>
                    <div className="user-order">
                       <div className="user-order-body">
                        <div className="order__title">
                                Ваш заказ:
                            </div>
                            {Object.keys(total['products']).map((item, key) => (
                                <div className="user-order__body" key={key}>
                                    <div className="order__item">{total['products'][item]}x {item}</div>
                                </div>                                
                            ))}
                            <div style={{marginTop:'12px', color:'#464445'}}>Итог: {total['total']} руб.</div>
                       </div>
                    </div>
                    <form action="." method='post' className='contact-form' onSubmit={(event) =>{
                        event.preventDefault()
                        setOrder({'loading':true})
                        SaveOrder(setOrder, event, param.shop_name)
                    }}>
                        <div className="contact-form__input">
                        <p><label htmlFor='phone'>Телефон:</label></p>
                            <input type="text" name='phone' />
                        </div>
                        <div className="contact-form__input">
                        <p><label htmlFor="first_name">Имя:</label></p>
                            <input type="text" name='first_name' value={user.first_name} onChange={(event)=>{
                                    setUser({...user, first_name:event.target.value})
                                }}/>
                        </div>
                        <div className="contact-form__input">
                        <p><label htmlFor="last_name">Фамилия:</label></p>
                            <input type="text" name='last_name' value={user.last_name} onChange={(event)=>{
                                    setUser({...user, last_name:event.target.value})
                                }}/>
                        </div>
                        <div className="contact-form__input">
                        <p><label htmlFor="email">Email:</label></p>
                            <input type="text" name='email' value={user.email} onChange={(event)=>{
                                    setUser({...user, email:event.target.value})
                                }}/>
                        </div>
                        <div className="contact-form__input">
                        <p><label htmlFor="details">Детали заказа:</label></p>
                            <input type="text" name='details'/>
                        </div>

                        <button className='create' type='submit'>Создать заказ</button>
                    </form>
                </div>
                :
                (order['created'] ?
                <div style={{color:'#464445', fontSize:'20px'}}>
                    Ваш заказ успешно создан. Его номер: {order['created']}
                </div>
                :
                (order['loading']
                ?
                <div>Создание заказа...</div>
                :
                <div>Ошибка...</div>
                ))
                }

            </Container>
            <Footer />
        </div>
    )
}

export default OrderCreate