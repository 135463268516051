import axios from 'axios';
import PATH from './URL'


async function AddToCart(product_id){
    const response = await axios.get(`${PATH}/api/v1/add-to-cart/${product_id}`)
    return response.data
}

async function DelToCart(product_id){
    const response = await axios.get(`${PATH}/api/v1/del-to-cart/${product_id}`)
    return response.data
}

async function RemToCart(product_id){
    const response = await axios.get(`${PATH}/api/v1/rem-to-cart/${product_id}`)
    return response.data
}

async function getLenCart(func){
    const response = await axios.get(`${PATH}/api/v1/len_cart`)
    return func(response.data)
}

async function CheckCart(func){
    const response = await axios.get(`${PATH}/api/v1/check-cart`)
    return func(response.data)
}

async function GetTotal(func){
    const response = await axios.get(`${PATH}/api/v1/get_total_price`)
    return func(response.data)
}
export {AddToCart, CheckCart, DelToCart, RemToCart, GetTotal, getLenCart}