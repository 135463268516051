import { useParams } from "react-router-dom"
import Header from '../components/js/Header'
import Container from '../components/js/Container'
import Footer from '../components/js/Footer'
import { useMediaQuery } from "react-responsive";
import HeaderMobile from '../components/js/HeaderMobile'
import product from '../images/product.png'
import '../components/css/ProductDetail.css'
import BreadCrumbs from "../components/js/BreadCrumbs";
import { useEffect, useState } from "react";
import {GetProduct, GetStock} from '../api/GetProducts'
import PATH from "../api/URL";
import AddCartButton from "../components/js/AddCartButton";
import { CheckCart } from '../api/AddToCart'
import { truncNum } from "../components/js/Tools/TruncPrice";

const ProductDetail = () =>{

    let [product, setProduct] = useState({})
    let [shops, setShops] = useState({})
    let [button, setButton] = useState(false)
    let [cart, setCart] = useState({})
    let param = useParams()

    useEffect(() => {
        GetProduct(setProduct, param.product_slug)
        CheckCart(setCart)
    }, [])

    useEffect(() => {
        setShops(GetStock(setShops, product.id))
    }, [product])

    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });
    return(
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
                <div className="product__detail">
                    <div className="detail__image">
                        <img src={PATH + '/' + product?.image} alt="" />
                    </div>
                    <div className="detail__info">
                        <BreadCrumbs category={product.category}/>
                        <div className="detail__title">
                            {product.name}
                        </div>
                        <div className="detail__price">
                            {product.min_old_price == '0' 
                            ?
                            <span>от {product.min_price} руб</span>
                            :
                            <span>                            
                                от <span style={{textDecoration:'line-through'}}>{product.min_old_price}</span> {product.min_price} руб
                            </span>
                            }
                        </div>
                        <div className="detail__button">
                            <AddCartButton product_id={product.id} cart={cart} />
                        </div>
                        <div style={{borderTop:'1px solid lightgray', marginTop:'12px'}}></div>
                        <div className="detail__table">
                            <table className="table">
                                {product.country &&
                                <tr>
                                    <th>Страна:</th>
                                    <td>{product.country}</td>
                                </tr>
                                }
                                {/* <tr>
                                    <th>Производитель:</th>
                                    <td>{product.producer}</td>
                                </tr> */}
                                {product.strenght &&
                                <tr>
                                    <th>Крепость:</th>
                                    <td>{truncNum(product.strenght)}</td>
                                </tr>
                                }
                                {product.color_vine &&
                                <tr>
                                    <th>Цвет вина:</th>
                                    <td>{product.color_vine}</td>
                                </tr>
                                }
                                {product.color_beer&&
                                <tr>
                                    <th>Цвет пива:</th>
                                    <td>{product.color_beer}</td>
                                </tr>
                                }
                            </table>
                        </div>
                        <div className="detail__description">
                            <h2>Описание</h2>
                            {product.description}
                        </div>
                    </div>
                </div>
                <div className="detail__shops">
                        <h2>Наличие</h2>
                        {Object.values(shops).map((val, index) =>
                            <div className={index > 4 && button == false
                             ? 
                             'shop__card shop__card-closed' 
                             : 
                             index == 4 && button == false ? 'shop__card shop__card-blured' : 'shop__card'}
                             key={index}>
                                <div className='shop__card-body'>
                                    <div style={{width:'250px'}}>{val.shop}</div>
                                    {val.stock == 'много' ? <div style={{color:'green'}}>{val.stock}</div> :
                                     <div style={{color:'red'}}>{val.stock}</div>}
                                    <div><span style={{textDecoration:'line-through'}}>{val.price_old}</span> {val.price} ₽</div>
                                </div>
                            </div>                           
                        )}
                        <div className={shops.length > 4 ? 'shops__dropdown': 'dnone'}>
                            <div className={button  ? 'shops__dropdown-btn btn-rotated': 'shops__dropdown-btn'} onClick={() =>
                            setButton(!button)}></div>
                        </div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default ProductDetail