import '../../css/ProductFilter.css'


const FilterSugarVine = (props) => {
    return (
        <div className="filter">
        <div className="filter__body">
            <div className="filter__title">Содержание сахара</div>
            <div className="filter__content">
                <div className='filter__input'>
                    <input type="checkbox" name="sugar" value='Полусладкое'
                        onClick={props.getInputValue}/>
                    <label>Полусладкое</label>
                </div>
                <div className='filter__input'>
                    <input type="checkbox" name="sugar" value='Полусухое'
                        onClick={props.getInputValue}/>
                    <label>Полусухое</label>
                </div>
                <div className='filter__input'>
                    <input type="checkbox" name="sugar" value='Сладкое'
                        onClick={props.getInputValue}/>
                    <label>Сладкое</label>
                </div>
                <div className='filter__input'>
                    <input type="checkbox" name="sugar" value='Сухое'
                        onClick={props.getInputValue}/>
                    <label>Сухое</label>
                </div>
            </div>
        </div>
    </div>
    )
}

export default FilterSugarVine