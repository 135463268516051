import '../css/ProductFilter.css'
import 'react-range-slider-input/dist/style.css';
import { useEffect, useState } from 'react';
import '../css/RangeInput.css'
import { useParams, useSearchParams } from "react-router-dom";
import FilterPrice from './Filters/FilterPrice';
import FilterRegion from './Filters/FilterRegion';
import FilterStrenght from './Filters/FilterStrenght';
import FilterVolume from './Filters/FilterVolume';
import FilterSugarVine from './Filters/FilterSugar';
import FilterColorVine from './Filters/FilterColorVine';
import GetFilters from '../../api/Filters';
import FilterColorBeer from './Filters/FilterColorBeer';

const ProductFilters = (props) =>{
    let [searchParams, setSearchParams] = useSearchParams({})
    let [strength, setStrength] = useState(null)
    let [price, setPrice] = useState(null)
    let [volume, setVolume] = useState(null)
    let [params, setParams] =  useState({price:[], region:[], strenght:[], sugar:[], color:[]})
    let [prod, setProd] =  useState([])
    let category = useParams()
    let [filters, setFilters] = useState(['Цена', 'Регион'])

    // const handleChangeProduct = () => {
    //     try{
    //         props.change(prod)
    //     }
    //     catch{
    //         return
    //     }
    //   }
      useEffect(() => {
        GetFilters(setFilters, category.category_slug)
      }, [])
    
    // useEffect(() => {
    //     handleChangeProduct()
    // }, [prod])

    function contains(arr, filter) {
        return arr.includes(filter)
    }

    const getInputValue = (el) => {
        if (params[el.target.name].includes(el.target.value)){
            let p = JSON.parse(JSON.stringify(params))
            let index = p[el.target.name].indexOf(el.target.value)
            p[el.target.name].splice(index, 1)
            setParams(p)
        } else{
            setParams({...params, [el.target.name]:[...params[el.target.name], el.target.value]})
        }
        
    }
    const Filter = (event) => {
        event.preventDefault()
        setSearchParams(
            {
                page:1,
                region:[params.region],
                price:`${price?.[0] ? [price[0], price[1]] : ''}`,
                strength:`${strength?.[0] ? [strength[0], strength[1]]: ''}`,
                volume:`${volume?.[0] ? [volume[0], volume[1]]: ''}`,
                sugar:[params.sugar],
                color:[params.color]
            }
            )
    }
    return(
        <form action='.' method='get' className={props.filters ? 'product__filters filters-showed' : 'product__filters'}>
            <div className="product__filters-body">
                {contains(filters, 'Цена') &&  <FilterPrice  setcurrentVal={setPrice} currentVal={price} />}
                {contains(filters, 'Страна') && <FilterRegion getInputValue={getInputValue} />}
                {contains(filters, 'Сахар') && <FilterSugarVine getInputValue={getInputValue} />}
                {contains(filters, 'ЦветВино') && <FilterColorVine getInputValue={getInputValue} />}
                {contains(filters, 'ЦветПиво') && <FilterColorBeer getInputValue={getInputValue} />}
                {contains(filters, 'Крепость') && <FilterStrenght setcurrentVal={setStrength} currentVal={strength} />}
                {/* {contains(filters, 'объем') && <FilterVolume setcurrentVal={setVolume} currentVal={volume} />} */}
            </div>
                <div className="filter__body">
                    <div className="filters__button">
                        <button type='submit' onClick={(event) => {
                            Filter(event)
                            props.loading(true)
                        }
                            }>Применить фильтры</button>
                    </div>
                </div>
        </form>
    )
}

export default ProductFilters