import { createSlice } from '@reduxjs/toolkit'


const loginSlice = createSlice({
    name: 'login',
    initialState: {
        clickLogin: false,
        clickCross: true
    },
    reducers: {
      change: (state) => {
        state.clickLogin = !(state.clickLogin)
        state.clickCross = !(state.clickCross)
      }
    },
  })


export const { change } = loginSlice.actions

export default loginSlice.reducer