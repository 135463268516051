import { useEffect } from 'react';
import '../css/Pagination.css'
import { useSearchParams } from "react-router-dom";
import { useState } from 'react';


const Pagination = (props) =>{

    let [searchParams, setSearchParams] = useSearchParams()
    let [current, setCurrent] = useState(1)

    useEffect(() =>{
        if (current > searchParams.get('page')){
            setCurrent(1)
        }
    }, [searchParams])

    const Paginate = (event) =>{
        event.preventDefault()
        let filter_param = {
            region:props.param.get('region'),
            price:props.param.get('price'),
            sort:props.param.get('sort'),
            strength:props.param.get('strength'),
            volume:props.param.get('volume'),
            sugar:props.param.get('sugar'),
            color:props.param.get('color'),
            search:props.param.get('search')
        }
        for (let value of Object.keys(filter_param)){
            if (filter_param[value] == null){
                delete filter_param[value]
            }
        }
        let param = new URLSearchParams(event.target.search)
        setSearchParams({...filter_param, page:param.get('page')})
    }

    return (
        <div>
            {props.count 
            ?
            <div className="pagination">
                {props.len != 0 &&
                <div className="pagination__body">
                {current - 1 >= 1
                &&
                    <a className='pagination__element' style={{color:'#d10078'}} href={'?page=' + (current - 1)} onClick={(event) =>{
                        Paginate(event)
                        setCurrent(current - 1)
                        props.loading(true)
                    }}>{'<'}</a>
                }
                <div className='pagination__element' style={{color:'#464445'}}>
                    {current} из {props.count}
                </div>
                {current + 1 <= props.count
                &&
                    <a className='pagination__element' style={{color:'#d10078'}} href={'?page=' + (current + 1)} onClick={(event) =>{
                        Paginate(event)
                        setCurrent(current + 1)
                        props.loading(true)
                        window.scrollTo(0, 0);
                    }}>{'>'}</a>
                }
                </div>
                }
            </div>
            :
            <div></div>
            }
        </div>
    )
}

export default Pagination