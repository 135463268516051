import axios from 'axios';
import PATH from './URL'
import HandleUrl from './HandleUrl';


async function GetAllProducts(func, page=1, param){
    if (page != null){
        const response = await axios.get(`${PATH}/api/v1/all-products?offset=${page}`)
        return func([response.data.results, response.data.count, response.data.len])
    }
}

async function GetProductsByCat(func, page=1, category, param){
    if (page != null){
        const response = await axios.get(`${PATH}/api/v1/product-categories/${category}?offset=${page}`)
        return func([response.data.results, response.data.count, response.data.len, response.data.category])
    }
}

async function SearchProducts(func, param, page){
    if (page){
        const response = await axios.get(`${PATH}/api/v1/search/?search=${param}&offset=${page}`)
        return func([response.data.results, response.data.count, response.data.len])
    }

}

async function GetProduct(func, slug){
    const response = await axios.get(`${PATH}/api/v1/product/${slug}`)
    return func(response.data)
}

async function SortProduct(func, page, sort, category=null){
    const response = await axios.get(`${PATH}/api/v1/sort/?page=${page}&sort=${sort}&category=${category}`)
    return func([response.data.results, response.data.count, response.data.len])
}

async function GetStock(func, product_id){
    if (product_id){
        const response = await axios.get(`${PATH}/api/v1/stock/${product_id}`)
        return func(response.data)
    }
}

async function GetAvailableProducts(func){
    const response = await axios.get(`${PATH}/api/v1/choose-shop`)
    return func(response.data)

}


async function FilterProducts(func, dict, category=null){
    const response = await axios.get(
        `${PATH}/filter/?${HandleUrl(dict, category)}`
        )
    return func([response.data.results, response.data.count, response.data.len])
}
export {
    GetAllProducts,
    GetProduct, 
    GetStock, 
    FilterProducts, 
    GetProductsByCat, 
    SortProduct, 
    GetAvailableProducts,
    SearchProducts
}