import Header from '../components/js/Header'
import Container from '../components/js/Container'
import SliderWS from '../components/js/Slider'
import ProductFilters from '../components/js/ProductFilters'
import ProductInfo from '../components/js/ProductInfo'
import ProductContent from '../components/js/ProductContent'
import Pagination from '../components/js/Pagination'
import Footer from '../components/js/Footer'
import { useMediaQuery } from "react-responsive";
import HeaderMobile from '../components/js/HeaderMobile'
import SearchMobile from '../components/js/SearchMobile'
import {GetAllProducts, FilterProducts, SortProduct} from '../api/GetProducts'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSearchParams } from "react-router-dom";
import GetFilters from '../api/Filters'
import AgePopup from '../components/js/AgePopup'


const Agreement = () =>{
    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });
    return (
        <div>
        {isMobile ? <HeaderMobile /> : <Header />}
        <Container>
           <div style={{color:'#464445'}}>
            <p id="isPasted">
                <strong>Политика общества с ограниченной ответственностью "СЕВЕР"</strong>
            </p>
            <p>
                <strong>в отношении обработки персональных данных</strong>
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>1. Общие положения</strong>
            </p>
            <p>&nbsp;</p>
            <p>
                1.1. &nbsp;Настоящая Политика общества с ограниченной ответственностью
                "Север" в отношении обработки персональных данных (далее - Политика)
                разработана во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона
                от 27.07.2006 N 152-ФЗ "О персональных данных" (далее - Закон о персональных
                данных) в целях обеспечения защиты прав и свобод человека и гражданина при
                обработке его персональных данных, в том числе защиты прав на
                неприкосновенность частной жизни, личную и семейную тайну&nbsp;и определяет
                порядок обработки персональных данных и меры по обеспечению безопасности
                персональных данных, предпринимаемые ООО "Север".
            </p>
            <p>
                1.2. Политика действует в отношении всех персональных данных, которые
                обрабатывает общество с ограниченной ответственностью "Север" (далее также -
                Оператор, ООО "Севере") о посетителях&nbsp;веб-сайта&nbsp;
                <a href="https://winestore18.ru/">https://winestore18.ru/</a>.
            </p>
            <p>
                &nbsp; &nbsp; &nbsp; &nbsp;В отношении &nbsp;правоотношений &nbsp;не
                связанных с обработкой данных о посетителях&nbsp;веб-сайта&nbsp;
                <a href="https://winestore18.ru/">https://winestore18.ru/</a>. в&nbsp;ООО «Север»
                действует Положение о персональных данных.
            </p>
            <p>
                1.3. Политика распространяется на отношения в области обработки персональных
                данных, возникшие у Оператора как до, так и после утверждения настоящей
                Политики.
            </p>
            <p>
                1.4. Во исполнение требований ч. 2 ст. 18.1 Закона о персональных данных
                настоящая Политика публикуется в свободном доступе в
                информационно-телекоммуникационной сети Интернет на сайте Оператора&nbsp;
                <a href="https://winestore18.ru/">https://winestore18.ru/</a>.
            </p>
            <p>1.5. Основные понятия, используемые в Политике:</p>
            <p>
                <strong>персональные данные</strong>&nbsp;- любая информация, относящаяся к
                прямо или косвенно определенному или определяемому физическому лицу (
                <strong>субъекту персональных данных</strong>);
            </p>
            <p>
                <strong>оператор персональных данных (оператор)</strong>&nbsp;-
                государственный орган, муниципальный орган, юридическое или физическое лицо,
                самостоятельно или совместно с другими лицами организующие и (или)
                осуществляющие обработку персональных данных, а также определяющие цели
                обработки персональных данных, состав персональных данных, подлежащих
                обработке, действия (операции), совершаемые с персональными данными;
            </p>
            <p>
                <strong>обработка персональных данных</strong>&nbsp;- любое действие
                (операция) или совокупность действий (операций) с персональными данными,
                совершаемых с использованием средств автоматизации или без их использования.
                Обработка персональных данных включает в себя в том числе:
            </p>
            <ul style={{ color: "rgb(55,55,55)" }}>
                <li>сбор;</li>
                <li>запись;</li>
                <li>систематизацию;</li>
                <li>накопление;</li>
                <li>хранение;</li>
                <li>уточнение (обновление, изменение);</li>
                <li>извлечение;</li>
                <li>использование;</li>
                <li>передачу (распространение, предоставление, доступ);</li>
                <li>обезличивание;</li>
                <li>блокирование;</li>
                <li>удаление;</li>
                <li>уничтожение;</li>
            </ul>
            <p>
                <strong>автоматизированная обработка персональных данных</strong>&nbsp;-
                обработка персональных данных с помощью средств вычислительной техники;
            </p>
            <p>
                <strong>распространение персональных данных</strong>&nbsp;- действия,
                направленные на раскрытие персональных данных неопределенному кругу лиц;
            </p>
            <p>
                <strong>предоставление персональных данных</strong>&nbsp;- действия,
                направленные на раскрытие персональных данных определенному лицу или
                определенному кругу лиц;
            </p>
            <p>
                <strong>блокирование персональных данных</strong>&nbsp;- временное
                прекращение обработки персональных данных (за исключением случаев, если
                обработка необходима для уточнения персональных данных);
            </p>
            <p>
                <strong>уничтожение персональных данных</strong>&nbsp;- действия, в
                результате которых становится невозможным восстановить содержание
                персональных данных в информационной системе персональных данных и (или) в
                результате которых уничтожаются материальные носители персональных данных;
            </p>
            <p>
                <strong>обезличивание персональных данных</strong>&nbsp;- действия, в
                результате которых становится невозможным без использования дополнительной
                информации определить принадлежность персональных данных конкретному
                субъекту персональных данных;
            </p>
            <p>
                <strong>информационная система персональных данных</strong>&nbsp;-
                совокупность содержащихся в базах данных персональных данных и
                обеспечивающих их обработку информационных технологий и технических средств;
            </p>
            <p>
                <strong>трансграничная передача персональных данных</strong>&nbsp;- передача
                персональных данных на территорию иностранного государства органу власти
                иностранного государства, иностранному физическому лицу или иностранному
                юридическому лицу.
            </p>
            <p>
                <strong>Пользователь</strong>&nbsp;
                <strong>веб-сайта (субъект персональных данных) - &nbsp;</strong>любой
                посетитель веб-сайта<strong>&nbsp;</strong>
                <a href="https://winestore18.ru/">https://winestore18.ru/</a>.&nbsp;
            </p>
            <p>1.6. Основные права и обязанности Оператора.</p>
            <p>
                1.6.1. Оператор<strong>&nbsp;</strong>имеет право:
            </p>
            <ol className="decimal_type" style={{ color: "rgb(55,55,55)" }}>
                <li>
                самостоятельно определять состав и перечень мер, необходимых и достаточных
                для обеспечения выполнения обязанностей, предусмотренных Законом о
                персональных данных и принятыми в соответствии с ним нормативными
                правовыми актами, если иное не предусмотрено Законом о персональных данных
                или другими федеральными законами;
                </li>
                <li>
                поручить обработку персональных данных другому лицу с согласия субъекта
                персональных данных, если иное не предусмотрено федеральным законом, на
                основании заключаемого с этим лицом договора. Лицо, осуществляющее
                обработку персональных данных по поручению Оператора, обязано соблюдать
                принципы и правила обработки персональных данных, предусмотренные Законом
                о персональных данных;
                </li>
                <li>
                в случае отзыва субъектом персональных данных согласия на обработку
                персональных данных Оператор вправе продолжить обработку персональных
                данных без согласия субъекта персональных данных при наличии оснований,
                указанных в Законе о персональных данных.
                </li>
            </ol>
            <p>
                1.6.2. Оператор<strong>&nbsp;</strong>обязан:
            </p>
            <ol style={{ color: "rgb(55,55,55)" }}>
                <li>
                организовывать обработку персональных данных в соответствии с требованиями
                Закона о персональных данных;
                </li>
                <li>
                отвечать на обращения и запросы субъектов персональных данных и их
                законных представителей в соответствии с требованиями Закона о
                персональных данных;
                </li>
                <li>
                сообщать в уполномоченный орган по защите прав субъектов персональных
                данных (Федеральную службу по надзору в сфере связи, информационных
                технологий и массовых коммуникаций (Роскомнадзор)) по запросу этого органа
                необходимую информацию в течение 30 дней с даты получения такого запроса.
                </li>
                <li>
                предоставлять субъекту персональных данных по его просьбе информацию,
                касающуюся обработки его персональных данных;
                </li>
            </ol>
            <ol className="decimal_type" style={{ color: "rgb(55,55,55)" }}>
                <li>
                публиковать или иным образом обеспечивать неограниченный доступ к
                настоящей Политике в отношении обработки персональных данных.
                При&nbsp;осуществлении сбора персональных данных с использованием
                информационно-телекоммуникационных сетей, Оператор обязан опубликовать в
                соответствующей информационно-телекоммуникационной сети документ,
                определяющий его политику в отношении обработки персональных данных, и
                сведения о реализуемых требованиях к защите персональных данных, а также
                обеспечить возможность доступа к указанному документу с использованием
                средств соответствующей информационно-телекоммуникационной сети;
                </li>
                <li>
                принимать правовые, организационные и технические меры для защиты
                персональных данных от неправомерного или случайного доступа к ним,
                уничтожения, изменения, блокирования, копирования, предоставления,
                распространения персональных данных, а также от иных неправомерных
                действий в отношении персональных данных;
                </li>
                <li>
                &nbsp;прекратить передачу (распространение, предоставление, доступ)
                персональных данных, прекратить обработку и уничтожить персональные данные
                в порядке и случаях, предусмотренных Законом о персональных данных.
                </li>
            </ol>
            <p>
                1.7. Основные права субъекта персональных данных. Субъект персональных
                данных имеет право:
            </p>
            <ol className="decimal_type" style={{ color: "rgb(55,55,55)" }}>
                <li>
                получать информацию, касающуюся обработки его персональных данных, за
                исключением случаев, предусмотренных федеральными законами. Сведения
                предоставляются субъекту персональных данных Оператором в доступной форме,
                и в них не должны содержаться персональные данные, относящиеся к другим
                субъектам персональных данных, за исключением случаев, когда имеются
                законные основания для раскрытия таких персональных данных. Перечень
                информации и порядок ее получения установлен Законом о персональных
                данных;
                </li>
                <li>
                требовать от оператора уточнения его персональных данных, их блокирования
                или уничтожения в случае, если персональные данные являются неполными,
                устаревшими, неточными, незаконно полученными или не являются необходимыми
                для заявленной цели обработки, а также принимать предусмотренные законом
                меры по защите своих прав;
                </li>
                <li>
                выдвигать условие предварительного согласия при обработке персональных
                данных в целях продвижения на рынке товаров, работ и услуг;
                </li>
                <li>
                обжаловать в Роскомнадзоре или в судебном порядке неправомерные действия
                или бездействие Оператора<strong>&nbsp;</strong>при обработке его
                персональных данных.
                </li>
            </ol>
            <p>
                1.8. Контроль за исполнением требований настоящей Политики осуществляется
                уполномоченным лицом, ответственным за организацию обработки персональных
                данных у Оператора.
            </p>
            <p>
                1.9. Ответственность за нарушение требований законодательства Российской
                Федерации и нормативных актов ООО "Север" в сфере обработки и защиты
                персональных данных определяется в соответствии с законодательством
                Российской Федерации.
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>2. Цели сбора персональных данных</strong>
            </p>
            <p>&nbsp;</p>
            <p>
                2.1. Обработка персональных данных ограничивается достижением конкретных,
                заранее определенных и законных целей. Не допускается обработка персональных
                данных, несовместимая с целями сбора персональных данных.
            </p>
            <p>
                2.2. Обработке подлежат только персональные данные, которые отвечают целям
                их обработки.
            </p>
            <p>
                2.3. Обработка Оператором персональных данных осуществляется в следующих
                целях:
            </p>
            <ul style={{ color: "rgb(55,55,55)" }}>
                <li>
                обеспечение соблюдения Конституции Российской Федерации, федеральных
                законов и иных нормативных правовых актов Российской Федерации;
                </li>
                <li>
                осуществление своей деятельности в соответствии с уставом ООО "Север";
                </li>
                <li>
                &nbsp;контроль количества и качества выполняемой работы, обеспечение
                сохранности имущества;
                </li>
                <li>привлечение и отбор кандидатов на работу у Оператора;</li>
                <li>
                &nbsp;заполнение и передача в органы исполнительной власти и иные
                уполномоченные организации требуемых форм отчетности;
                </li>
                <li>ведение бухгалтерского учета;</li>
                <li>
                &nbsp;осуществление гражданско-правовых отношений (в т.ч.&nbsp;заключение,
                исполнение и прекращение гражданско-правовых договоров);
                </li>
                <li>
                информирование&nbsp;<strong>Пользователя веб-сайта</strong>
                &nbsp;посредством отправки электронных писем;
                </li>
                <li>
                предоставление доступа&nbsp;<strong>Пользователю веб-сайта</strong>&nbsp;к
                сервисам, информации и/или материалам, содержащимся на веб-сайте&nbsp;
                <a href="https://winestore18.ru/">https://winestore18.ru/</a>.
                </li>
                <li>
                направление&nbsp;<strong>Пользователю веб-сайта</strong>&nbsp;уведомлений
                о новых продуктах и услугах Оператора, специальных предложениях и
                различных событиях;*&nbsp;
                </li>
            </ul>
            <div>
                <ul style={{ color: "rgb(55,55,55)" }}>
                <li>сбор обезличенных данных Пользователей.**&nbsp;</li>
                </ul>
            </div>
            <p>
                *&nbsp;<strong>Пользователь веб-сайта</strong>&nbsp;всегда может отказаться
                от получения информационных сообщений, направив Оператору письмо на адрес
                электронной почты sale@glvz.net с пометкой «Отказ от уведомлений о новых
                продуктах и услугах и специальных предложениях».
            </p>
            <p>
                ** Обезличенные данные&nbsp;<strong>Пользователей веб-сайта</strong>,
                собираемые с помощью сервисов интернет-статистики, служат &nbsp; для сбора
                информации о действиях&nbsp;<strong>Пользователей</strong>&nbsp;на сайте,
                улучшения качества сайта и его содержания.
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>3. Правовые основания обработки персональных данных</strong>
            </p>
            <p>&nbsp;</p>
            <p>
                3.1. Правовым основанием обработки персональных данных является совокупность
                нормативных правовых актов, во исполнение которых и в соответствии с
                которыми Оператор осуществляет обработку персональных данных, в том числе:
            </p>
            <ul className="decimal_type" style={{ color: "rgb(55,55,55)" }}>
                <li>Конституция Российской Федерации;</li>
                <li>Гражданский кодекс Российской Федерации;</li>
                <li>Трудовой кодекс Российской Федерации;</li>
                <li>Налоговый кодекс Российской Федерации;</li>
                <li>
                Федеральный закон от 08.02.1998 N 14-ФЗ "Об обществах с ограниченной
                ответственностью";
                </li>
                <li>Федеральный закон от 06.12.2011 N 402-ФЗ "О бухгалтерском учете";</li>
                <li>
                Федеральный закон от 15.12.2001 N 167-ФЗ "Об обязательном пенсионном
                страховании в Российской Федерации";
                </li>
                <li>
                иные нормативные правовые акты, регулирующие отношения, связанные с
                деятельностью Оператора.
                </li>
            </ul>
            <p>3.2. Правовым основанием обработки персональных данных также являются:</p>
            <ul style={{ color: "rgb(55,55,55)" }}>
                <li>устав ООО "Север";</li>
                <li>
                договоры, заключаемые между Оператором и субъектами персональных данных;
                </li>
                <li>
                согласие субъектов персональных данных на обработку их персональных
                данных.
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <strong>4. Объем и категории обрабатываемых персональных данных,</strong>
            </p>
            <p>
                <strong>категории субъектов персональных данных</strong>
            </p>
            <p>&nbsp;</p>
            <p>
                4.1. Содержание и объем обрабатываемых персональных данных должны
                соответствовать заявленным целям обработки, предусмотренным в разд. 2
                настоящей Политики. Обрабатываемые персональные данные не должны быть
                избыточными по отношению к заявленным целям их обработки.
            </p>
            <p>
                4.2. Оператор может обрабатывать персональные данные следующих категорий
                субъектов персональных данных.
            </p>
            <p>4.2.1. &nbsp;Клиенты и контрагенты Оператора (физические лица):</p>
            <ul className="decimal_type" style={{ color: "rgb(55,55,55)" }}>
                <li>фамилия, имя, отчество;</li>
                <li>дата и место рождения;</li>
                <li>паспортные данные;</li>
                <li>адрес регистрации по месту жительства;</li>
                <li>контактные данные;</li>
                <li>замещаемая должность;</li>
                <li>индивидуальный номер налогоплательщика;</li>
                <li>номер расчетного счета;</li>
                <li>
                иные персональные данные, предоставляемые клиентами и контрагентами
                (физическими лицами), необходимые для заключения и исполнения договоров.
                </li>
            </ul>
            <p>
                4.2.2. Представители (работники) клиентов и контрагентов Оператора
                (юридических лиц):
            </p>
            <ul className="decimal_type" style={{ color: "rgb(55,55,55)" }}>
                <li>фамилия, имя, отчество;</li>
                <li>паспортные данные;</li>
                <li>контактные данные;</li>
                <li>замещаемая должность;</li>
                <li>
                иные персональные данные, предоставляемые представителями (работниками)
                клиентов и контрагентов, необходимые для заключения и исполнения
                договоров.
                </li>
            </ul>
            <p>
                4.2.3.&nbsp;<strong>Пользователи веб-сайта&nbsp;</strong>*:&nbsp;
            </p>
            <ul style={{ color: "rgb(55,55,55)" }}>
                <li>фамилия, имя, отчество (в том числе по отдельности).</li>
                <li>электронный адрес;</li>
                <li>номера телефонов;</li>
                <li>фотографии;</li>
                <li>дата рождения;</li>
                <li>адрес;&nbsp;</li>
            </ul>
            <div>
                <ul style={{ color: "rgb(55,55,55)" }}>
                <li>ссылка на персональный сайт и профиль в соцсетях.</li>
                </ul>
            </div>
            <p>
                *Также на сайте происходит сбор и обработка обезличенных данных о
                посетителях (в т.ч. файлов «cookie»,&nbsp;IP-адресе, местоположении) с
                помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и
                других).
            </p>
            <p>&nbsp;</p>
            <p>
                4.3. Обработка Оператором биометрических персональных данных (сведений,
                которые характеризуют физиологические и биологические особенности человека,
                на основании которых можно установить его личность) осуществляется в
                соответствии с законодательством Российской Федерации.
            </p>
            <p>
                4.4. Оператором не осуществляется обработка специальных категорий
                персональных данных, касающихся расовой, национальной принадлежности,
                политических взглядов, религиозных или философских убеждений, состояния
                здоровья, интимной жизни, за исключением случаев, предусмотренных
                законодательством РФ.
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>5. Порядок и условия обработки персональных данных</strong>
            </p>
            <p>&nbsp;</p>
            <p>
                5.1. Обработка персональных данных осуществляется Оператором в соответствии
                с требованиями законодательства Российской Федерации.
            </p>
            <p>
                5.2. Обработка персональных данных осуществляется с согласия субъектов
                персональных данных на обработку их персональных данных, а также без
                такового в случаях, предусмотренных законодательством Российской Федерации.
            </p>
            <p>
                5.3. Оператор осуществляет как автоматизированную, так и
                неавтоматизированную обработку персональных данных.
            </p>
            <p>
                5.4. К обработке персональных данных допускаются работники Оператора, в
                должностные обязанности которых входит обработка персональных данных.
            </p>
            <p>
                5.5. Обработка персональных данных субъектов персональных данных
                осуществляется путем:
            </p>
            <ul style={{ color: "rgb(55,55,55)" }}>
                <li>
                получения персональных данных в устной и письменной форме непосредственно
                от субъектов персональных данных;
                </li>
                <li>получения персональных данных из общедоступных источников;</li>
                <li>
                внесения персональных данных в журналы, реестры и информационные системы
                Оператора;
                </li>
            </ul>
            <ul className="decimal_type" style={{ color: "rgb(55,55,55)" }}>
                <li>использования иных способов обработки персональных данных.</li>
            </ul>
            <p>
                5.6. Оператор обрабатывает персональные данные&nbsp;
                <strong>Пользователя</strong>&nbsp;<strong>веб-сайта</strong>&nbsp;только в
                случае их заполнения и/или отправки&nbsp;
                <strong>Пользователем веб-сайта</strong>&nbsp;самостоятельно через
                специальные формы, расположенные на сайте&nbsp;
                <strong>https://glvz.net</strong>&nbsp;или направленные Оператору
                посредством электронной почты. Заполняя соответствующие формы и/или
                отправляя свои персональные данные Оператору,&nbsp;
                <strong>Пользователь веб-сайта</strong>&nbsp;выражает свое согласие с данной
                Политикой.
            </p>
            <p>
                5.7. Оператор обрабатывает обезличенные данные о&nbsp;
                <strong>Пользователе веб-сайта</strong>&nbsp;в случае, если это разрешено в
                настройках браузера&nbsp;<strong>Пользователя веб-сайта&nbsp;</strong>
                (включено сохранение файлов «cookie» и использование технологии JavaScript).
            </p>
            <p>
                5.8. Субъект персональных данных самостоятельно принимает решение о
                предоставлении его персональных данных и дает согласие свободно, своей волей
                и в своем интересе.
            </p>
            <p>
                5.9. Не допускается раскрытие третьим лицам и распространение персональных
                данных без согласия субъекта персональных данных, если иное не предусмотрено
                федеральным законом. Согласие на обработку персональных данных, разрешенных
                субъектом персональных данных для распространения, оформляется отдельно от
                иных согласий субъекта персональных данных на обработку его персональных
                данных.
            </p>
            <p>
                5.10. Передача персональных данных органам дознания и следствия, в
                Федеральную налоговую службу, Пенсионный фонд Российской Федерации, Фонд
                социального страхования и другие уполномоченные органы исполнительной власти
                и организации осуществляется в соответствии с требованиями законодательства
                Российской Федерации.
            </p>
            <p>
                5.11. Оператор принимает необходимые правовые, организационные и технические
                меры для защиты персональных данных от неправомерного или случайного доступа
                к ним, уничтожения, изменения, блокирования, распространения и других
                несанкционированных действий, в том числе:
            </p>
            <ul className="decimal_type" style={{ color: "rgb(55,55,55)" }}>
                <li>
                определяет угрозы безопасности персональных данных при их обработке;
                </li>
                <li>
                принимает локальные нормативные акты и иные документы, регулирующие
                отношения в сфере обработки и защиты персональных данных;
                </li>
                <li>
                назначает лиц, ответственных за обеспечение безопасности персональных
                данных в структурных подразделениях и информационных системах Оператора;
                </li>
                <li>создает необходимые условия для работы с персональными данными;</li>
                <li>организует учет документов, содержащих персональные данные;</li>
                <li>
                организует работу с информационными системами, в которых обрабатываются
                персональные данные;
                </li>
                <li>
                хранит персональные данные в условиях, при которых обеспечивается их
                сохранность и исключается неправомерный доступ к ним;
                </li>
                <li>
                организует обучение работников Оператора, осуществляющих обработку
                персональных данных.
                </li>
            </ul>
            <p>
                5.12. Оператор осуществляет хранение персональных данных в форме,
                позволяющей определить субъекта персональных данных, не дольше, чем этого
                требуют цели обработки персональных данных, если срок хранения персональных
                данных не установлен федеральным законом, договором.
            </p>
            <p>
                5.13. При сборе персональных данных, в том числе посредством
                информационно-телекоммуникационной сети Интернет, Оператор обеспечивает
                запись, систематизацию, накопление, хранение, уточнение (обновление,
                изменение), извлечение персональных данных граждан Российской Федерации с
                использованием баз данных, находящихся на территории Российской Федерации,
                за исключением случаев, указанных в Законе о персональных данных.
            </p>
            <p>
                5.14. Оператор осуществляет обработку персональных данных необходимых для
                исполнения договора, стороной которого либо выгодоприобретателем или
                поручителем по которому является субъект персональных данных, а также для
                заключения договора по инициативе субъекта персональных данных или договора,
                по которому субъект персональных данных будет являться выгодоприобретателем
                или поручителем.
            </p>
            <p>
                5.15. Срок обработки персональных данных определяется достижением целей, для
                которых были собраны персональные данные, если иной срок не предусмотрен
                договором или действующим законодательством.
            </p>
            <p>
                5.16.<strong>&nbsp;Пользователь веб-сайта</strong>&nbsp;может в любой момент
                отозвать свое согласие на обработку персональных данных, направив Оператору
                уведомление посредством электронной почты на электронный адрес Оператора
                sale@glvz.net с пометкой «Отзыв согласия на обработку персональных данных».
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>6. Актуализация, исправление, удаление и уничтожение</strong>
            </p>
            <p>
                <strong>персональных данных, ответы на запросы субъектов</strong>
            </p>
            <p>
                <strong>на доступ к персональным данным</strong>
            </p>
            <p>&nbsp;</p>
            <p>
                6.1. Подтверждение факта обработки персональных данных Оператором, правовые
                основания и цели обработки персональных данных, а также иные сведения,
                указанные в ч. 7 ст. 14 Закона о персональных данных, предоставляются
                Оператором субъекту персональных данных или его представителю при обращении
                либо при получении запроса субъекта персональных данных или его
                представителя.
            </p>
            <p>
                В предоставляемые сведения не включаются персональные данные, относящиеся к
                другим субъектам персональных данных, за исключением случаев, когда имеются
                законные основания для раскрытия таких персональных данных.
            </p>
            <p>Запрос должен содержать:</p>
            <ul style={{ color: "rgb(55,55,55)" }}>
                <li>
                номер основного документа, удостоверяющего личность субъекта персональных
                данных или его представителя, сведения о дате выдачи указанного документа
                и выдавшем его органе;
                </li>
                <li>
                сведения, подтверждающие участие субъекта персональных данных в отношениях
                с Оператором (номер договора, дата заключения договора, условное словесное
                обозначение и (или) иные сведения), либо сведения, иным образом
                подтверждающие факт обработки персональных данных Оператором;
                </li>
                <li>подпись субъекта персональных данных или его представителя.</li>
            </ul>
            <p>
                Запрос может быть направлен в форме электронного документа и подписан
                электронной подписью в соответствии с законодательством Российской
                Федерации.
            </p>
            <p>
                Если в обращении (запросе) субъекта персональных данных не отражены в
                соответствии с требованиями Закона о персональных данных все необходимые
                сведения или субъект не обладает правами доступа к запрашиваемой информации,
                то ему направляется мотивированный отказ.
            </p>
            <p>
                Право субъекта персональных данных на доступ к его персональным данным может
                быть ограничено в соответствии с ч. 8 ст. 14 Закона о персональных данных, в
                том числе, если доступ субъекта персональных данных к его персональным
                данным нарушает права и законные интересы третьих лиц.
            </p>
            <p>
                6.2. В случае выявления неточных персональных данных при обращении субъекта
                персональных данных или его представителя либо по их запросу или по запросу
                Роскомнадзора Оператор осуществляет блокирование персональных данных,
                относящихся к этому субъекту персональных данных, с момента такого обращения
                или получения указанного запроса на период проверки, если блокирование
                персональных данных не нарушает права и законные интересы субъекта
                персональных данных или третьих лиц.
            </p>
            <p>
                В случае подтверждения факта неточности персональных данных Оператор на
                основании сведений, представленных субъектом персональных данных или его
                представителем либо Роскомнадзором, или иных необходимых документов уточняет
                персональные данные в течение семи рабочих дней со дня представления таких
                сведений и снимает блокирование персональных данных.
            </p>
            <p>
                6.2.1.&nbsp;В случае выявления неточностей в персональных данных,&nbsp;
                <strong>Пользователь веб-сайта</strong>&nbsp;может актуализировать их
                самостоятельно, путем направления Оператору уведомление на адрес электронной
                почты Оператора sale@glvz.net с пометкой «Актуализация персональных данных».
            </p>
            <p>
                6.3. В случае выявления неправомерной обработки персональных данных при
                обращении (запросе) субъекта персональных данных или его представителя либо
                Роскомнадзора Оператор осуществляет блокирование неправомерно обрабатываемых
                персональных данных, относящихся к этому субъекту персональных данных, с
                момента такого обращения или получения запроса.
            </p>
            <p>
                6.4. При достижении целей обработки персональных данных, а также в случае
                отзыва субъектом персональных данных согласия на их обработку персональные
                данные подлежат уничтожению, если:
            </p>
            <ul style={{ color: "rgb(55,55,55)" }}>
                <li>
                иное не предусмотрено договором, стороной которого, выгодоприобретателем
                или поручителем по которому является субъект персональных данных;
                </li>
                <li>
                оператор не вправе осуществлять обработку без согласия субъекта
                персональных данных на основаниях, предусмотренных Законом о персональных
                данных или иными федеральными законами;
                </li>
                <li>
                иное не предусмотрено другим соглашением между Оператором и субъектом
                персональных данных.
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <strong>7. Трансграничная передача персональных данных</strong>
            </p>
            <p>
                <strong>&nbsp;</strong>
            </p>
            <p>
                7.1. Оператор до начала осуществления трансграничной передачи персональных
                данных обязан убедиться в том, что иностранным государством, на территорию
                которого предполагается осуществлять передачу персональных данных,
                обеспечивается надежная защита прав субъектов персональных данных.
            </p>
            <p>
                7.2. Трансграничная передача персональных данных на территории иностранных
                государств, не отвечающих вышеуказанным требованиям, может осуществляться
                только в случае наличия согласия в письменной форме субъекта персональных
                данных на трансграничную передачу его персональных данных и/или исполнения
                договора, стороной которого является субъект персональных данных.
            </p>
            <p>
                <strong>8. Конфиденциальность персональных данных</strong>
            </p>
            <p>
                8.1. Оператор и иные лица, получившие доступ к персональным данным, обязаны
                не раскрывать третьим лицам и не распространять персональные данные без
                согласия субъекта персональных данных, если иное не предусмотрено
                федеральным законом.
            </p>
            <p>
                <strong>9. Заключительные положения</strong>
            </p>
            <p>
                9.1. Пользователь может получить любые разъяснения по интересующим вопросам,
                касающимся обработки его персональных данных, обратившись к Оператору с
                помощью электронной почты sale@glvz.net.
            </p>
            <p>
                9.2. В данном документе будут отражены любые изменения политики обработки
                персональных данных Оператором. Политика действует бессрочно до замены ее
                новой версией.
            </p>
            </div>
        </Container>
        <Footer />
    </div>
    )
}

export default Agreement    