import axios from "axios";
import PATH from "./URL";

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) == (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

async function GetUserOrders(func) {
    const response = await axios.get(`${PATH}/api/v1/get_user_orders`)
    return func(response.data)
}


async function UpdateUserData(func, data) {
    const csrftoken = getCookie('csrftoken');
    let options = {
        method:'POST',
        headers:{ 'X-CSRFToken': csrftoken, 'Content-Type' : 'application/x-www-form-urlencoded'},
        data:{
            'email':data.email,
            'username':data.email,
            'first_name':data.first_name,
            'last_name':data.last_name
            },
        url: `${PATH}/api/v1/update_user_data`
    }
    const response = await axios(options)
    return func(response.data)
}
export {GetUserOrders, UpdateUserData}