import '../css/Container.css'

const Container = (props) =>{
    return(
        <div className="container">
        <div className="container__body">
            {/* <div className="main-slider" style="width:95%; position:relative; display:flex; justify-content:center;">
                <div class="mail-slider__item">
                    <img src="" style="width:100%" alt="Alternate Text" />
                </div>
            </div> */}
            {props.children}
        </div>
    </div>
    )
}

export default Container