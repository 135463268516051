import '../css/ProductContent.css'
import PATH from '../../api/URL'
import AddCartButton from './AddCartButton'
import { useEffect, useState } from 'react'
import { CheckCart, DelToCart } from '../../api/AddToCart'
import { truncNum } from './Tools/TruncPrice'

const ProductContent = (props) =>{
    let [cart, setCart] = useState({})

    useEffect(() =>{
        CheckCart(setCart)
    }, [])

    return(
        <div className="product__content" style={props.loading ? {filter:'blur(1px)', opacity:'0.6'} : {}}>
            {typeof props.product == 'string' || !props.product ?
            <div style={{color: 'rgb(70, 68, 69)'}}>
                Загрузка...
            </div>
            :
            <div>
                {Object.values(props.product).length == 0 && <div style={{color:'rgb(70, 68, 69)'}}>Ничего не найдено...</div>}
                {Object.values(props.product).map((item, index) =>(
                    <div className="product__card" key={index}>
                        <div className="product__card-body">
                            <div className="product__img">
                                <a href={PATH +'/product/' + item.slug}><img src={PATH + '/' + item.image} alt="" /></a>
                            </div>
                            <div className="product__title">
                            <a href={PATH +'/product/' + item.slug}>{item.name}</a>
                            </div>
                            <div className="product__category">
                                <span> Категория: {item.category}</span>
                            </div>
                            <div className="product__price">
                                {item.min_old_price == '0' 
                                ?
                                <div>
                                    от {truncNum(item.price)} руб
                                </div>
                                :
                                <div>
                                    от <span style={{textDecoration:'line-through'}}>{truncNum(item.min_old_price)}</span> {truncNum(item.price)} руб
                                </div>
                                }
                        
                            </div>
                            <div className="product__add-to-cart-button">
                                <AddCartButton product_id={item.id} cart={cart}/>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
        }
        </div>
    )
            }



export default ProductContent