function HandleUrl(params, category) {
    console.log(params.get('sort'));
    return (
    `regions=${params.get('region')}&` 
    +
    `price=${params.get('price')}&`
    + 
    `offset=${params.get('page')}&`
    +
    `sort=${params.get('sort')}&`
    +
    `category=${category}&`
    +
    `strength=${params.get('strength')}&`
    +
    `volume=${params.get('volume')}&`
    +
    `color=${params.get('color')}&`
    +
   `sugar=${params.get('sugar')}`
    )
}


export default HandleUrl
