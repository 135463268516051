import AddCartButton from './AddCartButton';
import { RemToCart } from '../../api/AddToCart';
import PATH from '../../api/URL';
import { useSelector, useDispatch } from 'react-redux'
import {unsetQuantity} from '../../store/quantity.slice.js'
import { truncNum } from "../../components/js/Tools/TruncPrice";


const CartItemPC = (props) => {
    let dispatch = useDispatch()
    return (
        <div className={props.item.quantity == 0 ? 'cart__card dnone' : 'cart__card'}>
        <div className="card__body">
            <div className="card__info">
                <img src={PATH + '/' + props.item.image} alt="" />
                <div className="card-text">
                    <div className="card-text-title">{props.item.product}</div>
                    {props.item.country && 
                        <div className="card-subtitle">
                            <span style={{color:'gray', fontWeight:400}}>Страна:</span>
                            <span style={{color:'#464445;', fontWeight:400}}> {props.item.country}</span>
                        </div>
                    }
                    {props.item.strength && 
                        <div className="card-subtitle">
                            <span style={{color:'gray', fontWeight:400}}>Крепость:</span>
                            <span style={{color:'#464445;', fontWeight:400}}> {truncNum(props.item.strength)} %</span>
                        </div>
                    }
                </div>
            </div>
            <div className="card__price">
                {props.for_basket 
                ?
                    <div>
                        от <span style={{color:'gray', textDecoration:'line-through'}}>{props.item.old_price} руб</span> <span>{props.item.price} руб</span>
                    </div>
                :
                 <div>{props.item.price} руб</div>
                 }
            </div>
            {props.for_basket 
            ? 
            <div style={{display:'flex', justifyContent:'space-between', width:'150px'}}>
                <AddCartButton product_id={props.item.id} change={props.ChangeProduct} cart={props.cart}/>
                <div className="card-delete" onClick={() =>{
                    RemToCart(props.item.id)
                    dispatch(unsetQuantity(props.products[props.item.id]['quantity']))
                    props.setProduct({...props.products, [props.item.id]:{...props.products[props.item.id], quantity:0}})
                }}>
                    x
                </div>
            </div>
            :
            <div  style={{color:'#464445', fontSize:'20px'}}>
                {props.item.quantity} шт.
            </div>
            }

        </div>
    </div>
    )
}

export default CartItemPC