import '../components/css/Cart.css'
import Footer from '../components/js/Footer'
import { useMediaQuery } from "react-responsive";
import HeaderMobile from '../components/js/HeaderMobile'
import Container from '../components/js/Container'
import Header from '../components/js/Header'
import image from '../images/bigcart.png'
import { useState } from 'react';
import { useEffect } from 'react';
import { CheckCart } from '../api/AddToCart';
import { GetUser } from '../api/Login';
import CartItemPC from '../components/js/CartItemPC';
import CartItemMB from '../components/js/CartItemMB';
import { GetTotal } from '../api/AddToCart';
import {Decimal} from 'decimal.js';



const Cart = () =>{

    let [products, setProduct] = useState({})
    let [user, setUser] = useState([])
    let [cart, setCart] = useState({})
    let [total, setTotal] = useState(0)

    useEffect(() =>{
        CheckCart(setCart)
        GetTotal(setTotal)
    }, [])
    const ChangeProduct = (value) => {
        setProduct({...products, [value.id]:{...products[value.id], quantity:value.quantity}})
    }
    useEffect(() =>{
        GetTotal(setTotal)
    }, [products])

    const CartTotal = () =>{
        let totalQuantity = 0
        let totalPrice = new Decimal(0)
        let item
        for (item of Object.values(products)){
            let ipr = new Decimal(item.price)
            totalQuantity += item.quantity
            totalPrice = totalPrice.plus(ipr.times(item.quantity))
        }

        return [totalQuantity, totalPrice.toString()]
    }

    useEffect(() => {
        CheckCart(setProduct)
        GetUser(setUser)
    }, [])

    let isMobile = useMediaQuery({
        query: "(max-width: 786px)"
      });
    
    return(
        <div>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Container>
            <div className="title" style={CartTotal()[0] == 0 ? {display:'none'} : {}}>Корзина</div>
                <div className="cart">
                    <div className="cart__body">
                        {Object.values(products).map((item) =>(
                        <div>
                            {isMobile ?
                            <CartItemMB
                                for_basket={true} 
                                item={item}
                                ChangeProduct={ChangeProduct}
                                products={products}
                                setProduct={setProduct}
                                cart={cart}
                            />
                            :                         
                            <CartItemPC
                                for_basket={true}
                                item={item}
                                ChangeProduct={ChangeProduct}
                                products={products}
                                setProduct={setProduct}
                                cart={cart}
                            />}
                        </div>
                        ))}
                        <div className={CartTotal()[0] == 0 ? 'dnone' : 'total'}>
                            <div className="total__body">
                                <div className="total__title">
                                Итог:
                                </div>
                                <div className="total__quantity">
                                    Количество товаров.................{CartTotal()[0]} шт.
                                </div>
                                <div className="total__price">
                                    Цена.........................................от {CartTotal()[1]} руб
                                </div>
                                <div className="total__button">
                                    {(user == [] || user == '-')
                                    ?
                                    <div style={{color:"#d10078"}}>
                                        Чтобы продолжить оформление заказа, необходимо войти
                                    </div>
                                    :
                                    <a href="choose-shop">
                                        <button>Оформить заказ</button>
                                    </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={CartTotal()[0] == 0 ? 'empty__cart' : 'dnone'}>
                    <div className="empty__cart-body">
                        <div className="empty__cart-title">
                            <div>Ваша корзина пуста.</div>
                        </div>
                        <div className="empty__cart-text">
                            <div>
                            Добавьте товар в корзину, и он появятся в ней автоматически
                            </div>
                        </div>
                        <div className="catalog__button">
                            <a href="http://winestore18.ru/categories/Vino-000007982"><button>Перейти в каталог вин</button></a>
                        </div>
                        <div className="empty__cart-text">
                            <div>
                            Посмотреть товары на <a href="/" style={{textDecoration:'none', color:'#d10078'}}>главной странице</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default Cart